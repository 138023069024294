import { Form, DateRangePicker, Input, Select, Button, Table, Pagination, Tag } from 'element-react'
import React, { Component } from 'react'
import apiV2 from '../../api/api-v2'
import Paste from '../../components/paste'
import Money from '../../components/Money'
import Text from '../../components/text'
import { OrderType, OrderTypeListNoExtension as OrderTypeList } from './types'
import moment from 'moment'
import NewUserForm from './new_user_switch'
import { CommonTableFields, PayableTableFields, PaymentToBeMadeTableOpt, RiskoFields } from './assembly/order'

import OperatorInfo from '../member/operator_info'
import UserInfo from '../member/user_info'

import OrderPayment from './order_payment'
import OrderInfo from './order_info'

import OrderStages from './order_stages'
import OrderApprovalLimit from './order_approval_limit'
import OrderChangeSinglePeriod from './assembly/order_change-single-period'
import PropTypes from 'prop-types'

/**
 * 订单
 */
export default class OrderList extends Component {

    static ListResultEmpty = { records: [], total: 0 }

    static propTypes = {
        queryParam: PropTypes.object,
        rquestListFunction: PropTypes.func,
        readOnly: PropTypes.bool
    }


    constructor(props) {
        super(props)

        this.state = {
            orderColumns: [
                {
                    label: '用户姓名',
                    prop: 'userName',
                    width: '100',
                    align: 'center',
                    render: ({ userName }) => <Paste content={userName} />,
                    fixed: true
                },
                {
                    label: '是否新客',
                    prop: 'num',
                    width: '120',
                    align: 'center',
                    render: ({ num }) => {
                        return num > 0 ? (<i className="el-icon-close" />) : (<i color="red" className="el-icon-check" />)
                    }
                },
                {
                    label: '单号',
                    prop: 'orderCode',
                    width: '180',
                    align: 'center',
                    render: ({ orderCode }) => <Paste content={orderCode} />
                },
                {
                    label: '渠道',
                    prop: 'channel',
                    width: '150',
                    align: 'center',
                    render: ({ channel }) => <Paste content={channel} />
                },
                {
                    label: '借款额度',
                    width: '120',
                    align: 'center',
                    prop: 'quota',
                    render: ({ quota }) => <Money value={quota} />
                },
                {
                    label: '申请借款周期',
                    width: '140',
                    align: 'center',
                    prop: 'deadlineDay',
                    render: ({ deadlineDay }) => <Money value={deadlineDay} />
                },
                {
                    label: '借款次数',
                    prop: 'num',
                    align: 'center',
                    width: '120',
                },
                {
                    label: '手机号',
                    prop: 'phone',
                    align: 'center',
                    width: '120',
                },
                {
                    label: '服务费',
                    prop: 'rateMoney',
                    align: 'center',
                    width: '120',
                    render: ({ rateMoney }) => <Money value={rateMoney} />
                },

                {
                    label: '订单类型',
                    prop: 'type',
                    width: '120',
                    align: 'center',
                    render: ({ type }) => {
                        const that = OrderType[type]
                        return <Tag type={that.theme}>{that.name}</Tag>
                    }
                },
                ...RiskoFields,
                ...CommonTableFields,
                ...PayableTableFields,
                // {
                //     label: '雷达分',
                //     prop: 'kaiqiScore',
                //     width: '120',
                //     align: 'center',
                //     render: ({ kaiqiScore }) => <Money value={kaiqiScore} />
                // },
                // {
                //     label: '米融分',
                //     prop: 'zhimiScore',
                //     width: '120',
                //     align: 'center',
                //     render: ({ zhimiScore }) => <Money value={zhimiScore} />
                // },
                {
                    label: '下单时间',
                    prop: 'createTime',
                    width: '140',
                    align: 'center',

                },
                {
                    label: '审核时间',
                    prop: 'auditTime',
                    width: '140',
                    align: 'center',
                    fixed: true
                },
                {
                    label: '到账银行',
                    prop: 'bankNum,bankName',
                    align: 'center',
                    width: '120',
                    fixed: 'right',
                    render: ({ bankNum, bankName, bankType }) =>
                    (
                        <>
                            {bankType ? <Text color="red">{bankType}</Text> : null}
                            <Paste color="green" content={`${bankName || '_'}:${bankNum || '无卡号'}`} />
                        </>
                    )

                }, {
                    label: '到账金额',
                    prop: 'arrivalAmount',
                    align: 'center',
                    width: '120',
                    render: ({ arrivalAmount }) => <Money value={arrivalAmount} />
                },
                PaymentToBeMadeTableOpt(this)
            ],
            orderList: OrderList.ListResultEmpty,
            queryForm: Object.assign({
                orderCode: '',
                size: 10,
                current: 1,
                type: '',
                isNewUser: null,
                beginAuditTime: null,
                endAuditTime: null,
                channel: '',
                phone: '',
                orderApprovalId: null,
                orderStagesId: null
            }, props.queryParam),
            userInfoId: null,
            phoneInfoId: null,
            orderInfoId: null,
            singlePeriodId: null,
            paymentStateId: null
        }
    }

    componentDidMount() {
        this.getOrderList()
    }

    //组件销毁前调用，清除一些事件(比如定时事件)
    componentWillUnmount() {
        this.setState = () => {
            return
        }
    }

    async getOrderList() {
        const { body } = await (this.props.rquestListFunction || apiV2.getOrderPassList)({ data: this.state.queryForm })
        this.setState({ orderList: body || OrderList.ListResultEmpty })
    }

    changeQueryForm(name, value, formatFn, listRefresh) {
        if (formatFn) value = formatFn(value)

        const queryForm = Object.assign(this.state.queryForm, { [name]: value })
        this.setState({ queryForm }, () => {
            if (listRefresh) this.getOrderList()
        })
    }

    datesAdapterElUI(date1String, date2String) {
        if (!date1String || !date2String) return [null, null]
        return [new Date(date1String), new Date(date2String)]
    }

    changeQueryFormDate(value, nameStart, nameEnd) {
        const format = 'YYYY-MM-DD'
        let start = null, end = null
        if (value && value.length > 1) {
            start = moment(value[0]).format(format)
            end = moment(value[1]).format(format)
        }

        const queryForm = Object.assign(this.state.queryForm, { [nameStart]: start, [nameEnd]: end })
        this.setState({ queryForm })

    }

    onPaymentState() {
        this.setState({ paymentStateId: null })
        this.getOrderList()
    }

    singlePeriodClose() {
        this.setState({ singlePeriodId: null })
        this.getOrderList()
    }

    orderStagesClose() {
        this.setState({ orderStagesId: null })
        this.getOrderList()
    }


    render() {

        const { orderColumns, orderList, queryForm } = this.state

        return (
            <>
                <Form inline>
                    <Form.Item label="订单号">
                        <Input value={queryForm.orderCode} onChange={(e) => this.changeQueryForm('orderCode', e)} />
                    </Form.Item>

                    <Form.Item label="手机号">
                        <Input value={queryForm.phone} onChange={(e) => this.changeQueryForm('phone', e)} />
                    </Form.Item>

                    <Form.Item label="用户姓名">
                        <Input value={queryForm.userName} onChange={(e) => this.changeQueryForm('userName', e)} />
                    </Form.Item>

                    <Form.Item label="渠道号">
                        <Input value={queryForm.channel} onChange={(e) => this.changeQueryForm('channel', e)} />
                    </Form.Item>

                    <Form.Item label="类型">
                        <Select value={queryForm.type} clearable onChange={(e) => this.changeQueryForm('type', e)}>
                            {OrderTypeList.map(({ key, name }) => <Select.Option key={key} label={name} value={key} />)}
                        </Select>
                    </Form.Item>

                    <Form.Item label="查询新老客">
                        <NewUserForm value={queryForm.isNewUser} onChangeValue={(e) => this.changeQueryForm('isNewUser', e)} />
                    </Form.Item>

                    <Form.Item label="审核时间">
                        <DateRangePicker value={this.datesAdapterElUI(queryForm.beginAuditTime, queryForm.endAuditTime)}
                            onChange={(e) => this.changeQueryFormDate(e, 'beginAuditTime', 'endAuditTime')} />
                    </Form.Item>


                    <Form.Item>
                        <Button onClick={() => this.getOrderList()} type="primary">{'搜索'}</Button>
                    </Form.Item>


                    <Table border columns={orderColumns} data={orderList.records} />


                    <Pagination total={orderList.total} current={queryForm.current} size={queryForm.size}
                        onCurrentChange={(e) => this.changeQueryForm('current', e, null, true)}
                        onSizeChange={(e) => this.changeQueryForm('size', e, null, true)}
                    />
                </Form>

                <OrderInfo orderId={this.state.orderInfoId} onClose={() => this.setState({ orderInfoId: null })} />
                <OrderPayment orderId={this.state.paymentStateId} onClose={() => this.onPaymentState()} />
                <OperatorInfo userId={this.state.phoneInfoId} onClose={() => this.setState({ phoneInfoId: null })} />
                <UserInfo userId={this.state.userInfoId} onClose={() => this.setState({ userInfoId: null })} />

                <OrderStages orderId={this.state.orderStagesId} onClose={() => this.orderStagesClose()} />
                <OrderApprovalLimit orderId={this.state.orderApprovalId} onClose={() => this.setState({ orderApprovalId: null })} />
                <OrderChangeSinglePeriod orderId={this.state.singlePeriodId} onClose={() => this.singlePeriodClose()} />

            </>
        )
    }
}
