import { blobDownloadFiles } from '../utils/app'
import { getUserToken } from './user'
import qs from 'qs'
import moment from 'moment'

/**
 * 接口主机地址
 */
const API_HOST = process.env.REACT_APP_API_V2_HOST

/**
 * 导入白名单接口主机地址
 */
const API_HOST_IMPORT = process.env.REACT_APP_API_IMPORT_HOST || API_HOST + "/while_list_loan_import"

const FetchJsonHeaders = {
    'Content-Type': 'application/json'
}

const AppType = 0
const DeviceNum = navigator.userAgent

function buildFetchUsetTokenHeaders(token = getUserToken()) {
    return {
        'admin-token': token
    }
}

function loginFailureHandle(dataBady) {
    if (dataBady.code === '700') {
        window.location.href = document.URL.split('#')[0] + '#/login'
    }

    return dataBady
}


export default {
    /**
     * 发送短信验证码
     * @param {*} phone  手机号码
     * @returns 发送结果
     */
    async sendLoginPhoneVerificationCode(phone) {
        const response = await fetch(API_HOST + `/admin/index/getCode/${phone}`, {
            method: 'GET',
            // body: JSON.stringify({ mobile: phone, appType: AppType, deviceNum: DeviceNum }),
            headers: FetchJsonHeaders
        })
        return await response.json()
    },

    /**
     * 短信验证码登陆
     * @param {*} code 短信验证码
     * @param {*} phone 手机号
     * @param {*} lat 地理定位：精度
     * @param {*} log 地理定位：纬度
     */
    async phoneVerificationCodeLogin(code, phone, lat = 0, log = 0) {
        const response = await fetch(API_HOST + '/admin/index/login', {
            method: 'POST',
            body: JSON.stringify({
                mobile: phone, appType: AppType, deviceNum: DeviceNum,
                code, latitude: lat, longitude: log
            }),
            headers: FetchJsonHeaders
        })
        return await response.json()
    },

    /**
     * 获取已登陆管理员的系统菜单列表
     */
    async getLoginManagementSystemMenu(token = getUserToken()) {
        const response = await fetch(API_HOST + '/admin/sysPerm/list', {
            method: 'POST',
            headers: { ...buildFetchUsetTokenHeaders(token) }
        })
        return loginFailureHandle(await response.json())
    },
    /**
     * 获取已登陆管理员的基本资料
     */
    async getUserInfo(token = getUserToken()) {
        const response = await fetch(API_HOST + '/admin/sysUser/user_info', {
            method: 'GET',
            headers: { ...buildFetchUsetTokenHeaders(token) }
        })
        return loginFailureHandle(await response.json())
    },

    /**
     * 查询所有角色列表
     * @param {*} param0 查询参数
     * @returns 结果
     */
    async getAllRoleList({ token = getUserToken(), pageSize, pageNum = 1 }) {
        const response = await fetch(API_HOST + `/admin/sysRole/list?current=${pageNum}&size=${pageSize}`, {
            method: 'POST',
            headers: { ...buildFetchUsetTokenHeaders(token), ...FetchJsonHeaders },
        })
        return loginFailureHandle(await response.json())
    },

    /**
    * 新增角色
    * @param {*} param0 查询参数
    * @returns 结果
    */
    async addSystemRole({ token = getUserToken(), roleName }) {
        const response = await fetch(API_HOST + '/admin/sysRole/add', {
            method: 'POST',
            headers: { ...buildFetchUsetTokenHeaders(token), ...FetchJsonHeaders },
            body: JSON.stringify({ roleName })
        })
        return loginFailureHandle(await response.json())
    },

    /**
    * 删除角色
    * @param {*} param0 查询参数
    * @returns 结果
    */
    async deleteSystemRole({ token = getUserToken(), roleId }) {
        const response = await fetch(API_HOST + '/admin/sysRole/remove?id=' + roleId, {
            method: 'POST',
            headers: { ...buildFetchUsetTokenHeaders(token) }
        })
        return loginFailureHandle(await response.json())
    },

    /**
    * 获取角色权限分配的系统菜单列表
    * @param {*} param0 查询参数
    * @returns 结果
    */
    async getRolePermissionAssignmentSysPerms(token = getUserToken()) {
        const response = await fetch(API_HOST + '/admin/sysPerm/role/permission_assignment/sys_perms', {
            method: 'GET',
            headers: { ...buildFetchUsetTokenHeaders(token) }
        })
        return loginFailureHandle(await response.json())
    },


    /**
    * 获取角色权限分配的系统菜单列表
    * @param {*} param0 查询参数
    * @returns 结果
    */
    async h5RiskUptById({ token = getUserToken(), param }) {
        const response = await fetch(API_HOST + '/admin/h5/risk/uptById', {
            method: 'POST',
            body: JSON.stringify(param),
            headers: {
                ...buildFetchUsetTokenHeaders(token),
                ...FetchJsonHeaders
            }
        })
        return loginFailureHandle(await response.json())
    },

    /**
     * 获取角色已经分配的权限
     * @param {*} param0 查询参数
     * @returns 结果
     */
    async getAllocationAuthorityKeys({ token = getUserToken(), roleId }) {
        const response = await fetch(API_HOST + `/admin/userRole/role_${roleId}/allocation/authority_keys?removeParamId=true`, {
            method: 'GET',
            headers: { ...buildFetchUsetTokenHeaders(token) }
        })
        return loginFailureHandle(await response.json())
    },
    /**
     * 角色分配的权限
     * @param {*} param0 查询参数
     * @returns 结果
     */
    async allocationAuthority({ token = getUserToken(), roleId, authorityKeys }) {
        const response = await fetch(API_HOST + `/admin/userRole/role_${roleId}/allocation_authority`, {
            method: 'POST',
            headers: { ...buildFetchUsetTokenHeaders(token), ...FetchJsonHeaders },
            body: JSON.stringify(authorityKeys)
        })

        if (response.status === 204) {
            return true
        }

        return loginFailureHandle(await response.json())
    },

    /**
     * 查询用户列表
     * @param {*} param0 查询参数
     * @returns 结果
     */
    async getSysUserList({ token = getUserToken(), phone, pageNum, pageSize }) {
        const response = await fetch(API_HOST + '/admin/sysUser/getList', {
            method: 'POST',
            headers: { ...buildFetchUsetTokenHeaders(token), ...FetchJsonHeaders },
            body: JSON.stringify({
                current: pageNum,
                phone,
                size: pageSize
            })
        })

        if (response.status === 204) {
            return true
        }

        return loginFailureHandle(await response.json())
    },

    /**
     * 禁用账号
     * @param {*} param0 查询参数
     * @returns 结果
     */
    async disableAccount({ userId, token = getUserToken(), }) {
        const response = await fetch(API_HOST + `/admin/sysUser/disable/account_${userId}`, {
            method: 'POST',
            headers: { ...buildFetchUsetTokenHeaders(token), ...FetchJsonHeaders },
        })

        if (response.status === 204) {
            return true
        }

        return loginFailureHandle(await response.json())
    },

    /**
     * 启用账号
     * @param {*} param0 查询参数
     * @returns 结果
     */
    async enableAccount({ userId, token = getUserToken(), }) {
        const response = await fetch(API_HOST + `/admin/sysUser/enable/account_${userId}`, {
            method: 'POST',
            headers: { ...buildFetchUsetTokenHeaders(token), ...FetchJsonHeaders },
        })

        if (response.status === 204) {
            return true
        }

        return loginFailureHandle(await response.json())
    },

    /**
    * 保存资料
    * @param {*} param0 查询参数
    * @returns 结果
    */
    async saveSysUser({ info, token = getUserToken(), roleId = '' }) {
        const response = await fetch(API_HOST + `/admin/sysUser/addSysPerm?roleId=${roleId}`, {
            method: 'POST',
            headers: { ...buildFetchUsetTokenHeaders(token), ...FetchJsonHeaders },
            body: JSON.stringify(info)
        })

        if (response.status === 204) {
            return true
        }

        return loginFailureHandle(await response.json())
    },

    /**
     * 所以角色
     * @param {*} param0 查询参数
     * @returns 结果
     */
    async getAllRole({ token = getUserToken() }) {
        const response = await fetch(API_HOST + '/admin/sysRole/all', {
            method: 'GET',
            headers: { ...buildFetchUsetTokenHeaders(token) }
        })

        if (response.status === 204) {
            return true
        }

        return loginFailureHandle(await response.json())
    },


    /**
     * 登出
     * @param {*} param0  查询参数
     */
    async logout({ token = getUserToken() }) {
        if (token) {
            const response = await fetch(API_HOST + '/admin/index/loginOut', {
                method: 'GET',
                headers: { ...buildFetchUsetTokenHeaders(token) }
            })

            if (response.status === 204) {
                return true
            }

            return loginFailureHandle(await response.json())
        }
    },


    /**
     * 获取认证配置
     * @param {*} param0  查询参数
     */
    async getAuthConfig({ token = getUserToken() }) {
        const response = await fetch(API_HOST + '/admin/auth/list/list', {
            method: 'GET',
            headers: { ...buildFetchUsetTokenHeaders(token) }
        })

        if (response.status === 204) {
            return true
        }

        return loginFailureHandle(await response.json())
    },

    /**
    * 修改认证配置
    * @param {*} param0  查询参数
    */
    async updateAuthConfig({ token = getUserToken(), body }) {
        const response = await fetch(API_HOST + '/admin/auth/list/upt', {
            method: 'POST',
            headers: { ...buildFetchUsetTokenHeaders(token), ...FetchJsonHeaders },
            body: JSON.stringify(body)
        })

        if (response.status === 204) {
            return true
        }

        return loginFailureHandle(await response.json())
    },

    /**
     * 查询会员列表
     * @param {*} param0 参数
     * @returns 服务端数据
     */
    async getMemberList({ token = getUserToken(), bodyQuery }) {
        const response = await fetch(API_HOST + '/admin/user/getList', {
            method: 'POST',
            headers: { ...buildFetchUsetTokenHeaders(token), ...FetchJsonHeaders },
            body: JSON.stringify(bodyQuery)
        })

        if (response.status === 204) {
            return true
        }

        return loginFailureHandle(await response.json())
    },

    /**
    * 查询会员列表
    * @param {*} param0 参数
    * @returns 服务端数据
    */
    async getRiskBatchProcessing({ token = getUserToken(), bodyQuery }) {
        const response = await fetch(API_HOST + '/admin/batch/risk/getList', {
            method: 'POST',
            headers: { ...buildFetchUsetTokenHeaders(token), ...FetchJsonHeaders },
            body: JSON.stringify(bodyQuery)
        })

        if (response.status === 204) {
            return true
        }

        return loginFailureHandle(await response.json())
    },

    /**
     * 查询H5注册会员列表
     * @param {*} param0 参数
     * @returns 服务端数据
     */
    async getH5RiskRegisterList({ token = getUserToken(), bodyQuery }) {
        const response = await fetch(API_HOST + '/admin/h5/risk/getList', {
            method: 'POST',
            headers: { ...buildFetchUsetTokenHeaders(token), ...FetchJsonHeaders },
            body: JSON.stringify(bodyQuery)
        })

        if (response.status === 204) {
            return true
        }

        return loginFailureHandle(await response.json())
    },



    /**
     * 修改会员活动状态
     * @param {*} param0 参数
     * @returns 服务端数据
     */
    async updateMemberState({ token = getUserToken(), bodyState }) {
        const response = await fetch(API_HOST + '/admin/user/uptUserStatus', {
            method: 'POST',
            headers: { ...buildFetchUsetTokenHeaders(token), ...FetchJsonHeaders },
            body: JSON.stringify(bodyState)
        })

        if (response.status === 204) {
            return true
        }

        return loginFailureHandle(await response.json())
    },
    /**
    * 获取用户通讯录信息
    * @param {*} param0 参数
    * @returns 服务端数据
    */
    async getMobileBook({ token = getUserToken(), bodyState }) {
        const response = await fetch(API_HOST + '/admin/mobile/getMobileBook', {
            method: 'POST',
            headers: { ...buildFetchUsetTokenHeaders(token), ...FetchJsonHeaders },
            body: JSON.stringify(bodyState)
        })

        if (response.status === 204) {
            return true
        }

        return loginFailureHandle(await response.json())

    },
    /**
    * 获取用户运营商原始数据 报告数据
    * @param {*} param0 参数
    * @returns 服务端数据
    */
    async getMobileData({ token = getUserToken(), userId }) {
        const response = await fetch(API_HOST + `/admin/mobile/getMobileData/${userId}`, {
            method: 'GET',
            headers: { ...buildFetchUsetTokenHeaders(token) }
        })

        if (response.status === 204) {
            return true
        }

        return loginFailureHandle(await response.json())

    },

    /**
     * 获取用户运营商原始数据 全景雷达
     * @param {*} param0 参数
     * @returns 服务端数据
     */
    async getRiskRadar({ token = getUserToken(), userId }) {
        const response = await fetch(API_HOST + '/admin/risk/leida', {
            method: 'POST',
            headers: { ...buildFetchUsetTokenHeaders(token), ...FetchJsonHeaders },
            body: JSON.stringify({ userId })
        })

        if (response.status === 204) {
            return true
        }

        return loginFailureHandle(await response.json())
    },

    /**
    * 获取用户运营商原始数据 全景雷达
    * @param {*} param0 参数
    * @returns 服务端数据
    */
    async getRiskRadarRefresh({ token = getUserToken(), userId }) {
        const response = await fetch(API_HOST + `/admin/h5/risk/getLeidaByUserId/${userId}`, {
            method: 'POST',
            headers: { ...buildFetchUsetTokenHeaders(token), ...FetchJsonHeaders },
            body: JSON.stringify({ userId })
        })

        if (response.status === 204) {
            return true
        }

        return loginFailureHandle(await response.json())
    },



    /**
     * 获取用户运营商原始数据 全景雷达
     * @param {*} param0 参数
     * @returns 服务端数据
     */
    async getRiskRadarH5({ token = getUserToken(), userId }) {
        const response = await fetch(API_HOST + '/admin/h5/risk/leida', {
            method: 'POST',
            headers: { ...buildFetchUsetTokenHeaders(token), ...FetchJsonHeaders },
            body: JSON.stringify({ userId })
        })

        if (response.status === 204) {
            return true
        }

        return loginFailureHandle(await response.json())
    },

    /**
     * 获取用户运营商原始数据 全景雷达
     * @param {*} param0 参数
     * @returns 服务端数据
     */
    async getLeidaByUserId({ token = getUserToken(), userId, refresh }) {
        const response = await fetch(API_HOST + `/admin/risk/getLeidaByUserId/${userId}?refresh=${refresh}`, {
            method: 'POST',
            headers: { ...buildFetchUsetTokenHeaders(token), ...FetchJsonHeaders },
            body: JSON.stringify({ userId })
        })

        if (response.status === 204) {
            return true
        }

        return loginFailureHandle(await response.json())
    },


    /**
     * 获取用户运营商原始数据 探针c
     * @param {*} param0 参数
     * @returns 服务端数据
     */
    async getRiskProbeC({ token = getUserToken(), userId }) {
        const response = await fetch(API_HOST + '/admin/risk/tanzhenC', {
            method: 'POST',
            headers: { ...buildFetchUsetTokenHeaders(token), ...FetchJsonHeaders },
            body: JSON.stringify({ userId })
        })

        if (response.status === 204) {
            return true
        }

        return loginFailureHandle(await response.json())
    },


    /**
     * 获取用户运营商原始数据 探针c
     * @param {*} param0 参数
     * @returns 服务端数据
     */
    async getRiskProbeCH5({ token = getUserToken(), userId }) {
        const response = await fetch(API_HOST + '/admin/h5/risk/tanzhenC', {
            method: 'POST',
            headers: { ...buildFetchUsetTokenHeaders(token), ...FetchJsonHeaders },
            body: JSON.stringify({ userId })
        })

        if (response.status === 204) {
            return true
        }

        return loginFailureHandle(await response.json())
    },


    /**
     * 获取用户运营商原始数据 探针c
     * @param {*} param0 参数
     * @returns 服务端数据
     */
    async getRiskProbeCRefresh({ token = getUserToken(), userId }) {
        const response = await fetch(API_HOST + `/admin/h5/risk/getTanzhenCByUserId/${userId}`, {
            method: 'POST',
            headers: { ...buildFetchUsetTokenHeaders(token), ...FetchJsonHeaders },
            body: JSON.stringify({ userId })
        })

        if (response.status === 204) {
            return true
        }

        return loginFailureHandle(await response.json())
    },


    /**
     * 获取用户运营商原始数据 探针c
     * @param {*} param0 参数
     * @returns 服务端数据
     */
    async getTanzhenCByUserId({ token = getUserToken(), userId, refresh = false }) {
        const response = await fetch(API_HOST + `/admin/risk/getTanzhenCByUserId/${userId}?refresh=${refresh}`, {
            method: 'POST',
            headers: { ...buildFetchUsetTokenHeaders(token), ...FetchJsonHeaders },
            body: JSON.stringify({ userId })
        })

        if (response.status === 204) {
            return true
        }

        return loginFailureHandle(await response.json())
    },

    /**
    * 查询额度列表
    * @param {*} param0 参数
    * @returns 服务端数据
    */
    async getQuotaList({ token = getUserToken(), query }) {
        const response = await fetch(API_HOST + '/admin/quota/getList', {
            method: 'POST',
            headers: { ...buildFetchUsetTokenHeaders(token), ...FetchJsonHeaders },
            body: JSON.stringify(query)
        })

        if (response.status === 204) {
            return true
        }

        return loginFailureHandle(await response.json())

    },
    /**
    * 保存额度
    * @param {*} param0 参数
    * @returns 服务端数据
    */
    async saveQuota({ token = getUserToken(), data }) {
        const response = await fetch(API_HOST + '/admin/quota/uptOrSaveQuota', {
            method: 'POST',
            headers: { ...buildFetchUsetTokenHeaders(token), ...FetchJsonHeaders },
            body: JSON.stringify(data)
        })

        if (response.status === 204) {
            return true
        }

        return loginFailureHandle(await response.json())
    }
    ,
    /**
    * 查询额度延期列表
    * @param {*} param0 参数
    * @returns 服务端数据
    */
    async getDeadlineList({ token = getUserToken(), quotaId }) {
        const response = await fetch(API_HOST + `/admin/quota/getDeadlineList/${quotaId}`, {
            method: 'GET',
            headers: { ...buildFetchUsetTokenHeaders(token) }
        })

        if (response.status === 204) {
            return true
        }

        return loginFailureHandle(await response.json())

    },

    /**
    * 保存额度延期
    * @param {*} param0 参数
    * @returns 服务端数据
    */
    async saveDeadline({ token = getUserToken(), data }) {
        const response = await fetch(API_HOST + '/admin/quota/uptOrSaveQuotaDeadline', {
            method: 'POST',
            headers: { ...buildFetchUsetTokenHeaders(token), ...FetchJsonHeaders },
            body: JSON.stringify(data)
        })

        if (response.status === 204) {
            return true
        }

        return loginFailureHandle(await response.json())
    },

    /**
    * 获取展期费率列表
    * @param {*} param0 参数
    * @returns 服务端数据
    */
    async getQuotaDeferList({ token = getUserToken(), data }) {
        const response = await fetch(API_HOST + '/admin/quota/list', {
            method: 'POST',
            headers: { ...buildFetchUsetTokenHeaders(token), ...FetchJsonHeaders },
            body: JSON.stringify(data)
        })

        if (response.status === 204) {
            return true
        }

        return loginFailureHandle(await response.json())
    },

    /**
    * 保存展期费率
    * @param {*} param0 参数
    * @returns 服务端数据
    */
    async saveQuotaDefer({ token = getUserToken(), data }) {
        const response = await fetch(API_HOST + '/admin/quota/uptOrSaveQuotaDefer', {
            method: 'POST',
            headers: { ...buildFetchUsetTokenHeaders(token), ...FetchJsonHeaders },
            body: JSON.stringify(data)
        })

        if (response.status === 204) {
            return true
        }

        return loginFailureHandle(await response.json())
    },


    /**
     * 获取个人信息
     * @param {*} param0  参数
     * @returns  结果
     */
    async getAuthEmergency({ token = getUserToken(), userId }) {
        const response = await fetch(API_HOST + `/admin/auth/list/getAuthEmergency/${userId}`, {
            method: 'GET',
            headers: { ...buildFetchUsetTokenHeaders(token), ...FetchJsonHeaders }
        })

        if (response.status === 204) {
            return true
        }

        return loginFailureHandle(await response.json())

    },

    /**
    * 获取身份认证信息
    * @param {*} param0  参数
    * @returns  结果
    */
    async getIdCardAuth({ token = getUserToken(), userId }) {
        const response = await fetch(API_HOST + `/admin/auth/list/getIdCardAuth/${userId}`, {
            method: 'GET',
            headers: { ...buildFetchUsetTokenHeaders(token), ...FetchJsonHeaders }
        })

        if (response.status === 204) {
            return true
        }

        return loginFailureHandle(await response.json())
    },

    /**
    * 重新认证
    * @param {*} param0  参数
    * @returns  结果
    */
    async userRecertification({ token = getUserToken(), userId, authType }) {
        const response = await fetch(API_HOST + '/admin/auth/list/uptUserRetryAuth', {
            method: 'POST',
            headers: { ...buildFetchUsetTokenHeaders(token), ...FetchJsonHeaders },
            body: JSON.stringify({ userId, authType })
        })
        if (response.status === 204) {
            return true
        }

        return loginFailureHandle(await response.json())
    },

    /**
    * 手机认证审核
    * @param {*} param0  参数
    * @returns  结果
    */
    async userMobileAuthToSuccess({ token = getUserToken(), userId }) {
        const response = await fetch(API_HOST + '/admin/auth/list/uptMobileAuthToSuccess?userId=' + userId, {
            method: 'POST',
            headers: { ...buildFetchUsetTokenHeaders(token), ...FetchJsonHeaders },
        })
        if (response.status === 204) {
            return true
        }

        return loginFailureHandle(await response.json())
    },

    /**
    * 身份证认证审核
    * @param {*} param0  参数
    * @returns  结果
    */
    async userCardAuthToSuccess({ token = getUserToken(), userId }) {
        const response = await fetch(API_HOST + '/admin/auth/list/uptIdCardAuthToSuccess?userId=' + userId, {
            method: 'POST',
            headers: { ...buildFetchUsetTokenHeaders(token), ...FetchJsonHeaders },
        })
        if (response.status === 204) {
            return true
        }

        return loginFailureHandle(await response.json())
    },
    /**
    * 获取银行卡认证信息
    * @param {*} param0  参数
    * @returns  结果
    */
    async getAuthBank({ token = getUserToken(), userId }) {
        const response = await fetch(API_HOST + `/admin/auth/list/getAuthBank/${userId}`, {
            method: 'GET',
            headers: { ...buildFetchUsetTokenHeaders(token), ...FetchJsonHeaders },
        })
        if (response.status === 204) {
            return true
        }

        return loginFailureHandle(await response.json())

    },
    /**
    * 获取关联设备信息信息
    * @param {*} param0  参数
    * @returns  结果
    */
    async getUserDeviceList({ token = getUserToken(), userId }) {
        const response = await fetch(API_HOST + `/admin/auth/list/getUserDeviceList/${userId}`, {
            method: 'GET',
            headers: { ...buildFetchUsetTokenHeaders(token), ...FetchJsonHeaders },
        })
        if (response.status === 204) {
            return true
        }

        return loginFailureHandle(await response.json())

    },

    /**
    * 获取信用分数
    * @param {*} param0  参数
    * @returns  结果
    */
    async getZMScore({ token = getUserToken(), userId }) {
        const response = await fetch(API_HOST + '/admin/risk/getZhimiScore', {
            method: 'POST',
            headers: { ...buildFetchUsetTokenHeaders(token), ...FetchJsonHeaders },
            body: JSON.stringify({ userId })
        })
        if (response.status === 204) {
            return true
        }

        return loginFailureHandle(await response.json())

    },
    /**
    * 获取信用分数
    * @param {*} param0  参数
    * @returns  结果
    */
    async getMirongfenByUserId({ token = getUserToken(), userId }) {
        const response = await fetch(API_HOST + `/admin/risk/getMirongfenByUserId/${userId}`, {
            method: 'POST',
            headers: { ...buildFetchUsetTokenHeaders(token), ...FetchJsonHeaders },
            body: JSON.stringify({ userId })
        })
        if (response.status === 204) {
            return true
        }

        return loginFailureHandle(await response.json())

    },

    /**
    * 黑名单列表
    * @param {*} param0  参数
    * @returns  结果
    */
    async getBlackList({ token = getUserToken(), data }) {
        const response = await fetch(API_HOST + '/admin/black/blackList', {
            method: 'POST',
            headers: { ...buildFetchUsetTokenHeaders(token), ...FetchJsonHeaders },
            body: JSON.stringify(data)
        })
        if (response.status === 204) {
            return true
        }

        return loginFailureHandle(await response.json())

    },

    /**
    * 删除单列表
    * @param {*} param0  参数
    * @returns  结果
    */
    async deleteBlackList({ token = getUserToken(), id }) {
        const response = await fetch(API_HOST + `/admin/black/removeById?blackId=${id}`, {
            method: 'POST',
            headers: { ...buildFetchUsetTokenHeaders(token), ...FetchJsonHeaders },
        })
        if (response.status === 204) {
            return true
        }

        return loginFailureHandle(await response.json())
    },
    /**
    * 添加黑名单列表
    * @param {*} param0  参数
    * @returns  结果
    */
    async addBlackList({ token = getUserToken(), data }) {
        const response = await fetch(API_HOST + '/admin/black/addBlackList', {
            method: 'POST',
            headers: { ...buildFetchUsetTokenHeaders(token), ...FetchJsonHeaders },
            body: JSON.stringify(data)
        })
        if (response.status === 204) {
            return true
        }

        return loginFailureHandle(await response.json())

    },

    /**
      * 获取全部配置
      * @param {*} param0  参数
      * @returns  结果
      */
    async getAllConfig({ token = getUserToken() }) {
        const response = await fetch(API_HOST + '/admin/config/getGlobalConfigList', {
            method: 'POST',
            headers: { ...buildFetchUsetTokenHeaders(token), ...FetchJsonHeaders },
        })
        if (response.status === 204) {
            return true
        }

        return loginFailureHandle(await response.json())

    },
    /**
     * 保存全部配置
     * @param {*} param0  参数
     * @returns  结果
     */
    async saveSystemConfig({ token = getUserToken(), data }) {
        const response = await fetch(API_HOST + '/admin/config/uptById', {
            method: 'POST',
            headers: { ...buildFetchUsetTokenHeaders(token), ...FetchJsonHeaders },
            body: JSON.stringify(data)
        })
        if (response.status === 204) {
            return true
        }

        return loginFailureHandle(await response.json())

    },

    /**
    * 订单列表
    * @param {*} param0  参数
    * @returns  结果
    */
    async getOrderList({ token = getUserToken(), data }) {
        const response = await fetch(API_HOST + '/admin/order/auditOrderList', {
            method: 'POST',
            headers: { ...buildFetchUsetTokenHeaders(token), ...FetchJsonHeaders },
            body: JSON.stringify(data)
        })
        if (response.status === 204) {
            return true
        }

        return loginFailureHandle(await response.json())

    },

    /**
    * 失败列表
    * @param {*} param0  参数
    * @returns  结果
    */
    async getOrderFailList({ token = getUserToken(), data }) {
        const response = await fetch(API_HOST + '/admin/order/falseOrderList', {
            method: 'POST',
            headers: { ...buildFetchUsetTokenHeaders(token), ...FetchJsonHeaders },
            body: JSON.stringify(data)
        })
        if (response.status === 204) {
            return true
        }

        return loginFailureHandle(await response.json())

    },

    /**
    * 失败列表
    * @param {*} param0  参数
    * @returns  结果
    */
    async getOrderPaymentFailList({ token = getUserToken(), data }) {
        const response = await fetch(API_HOST + '/admin/order/paymentFailOrderList', {
            method: 'POST',
            headers: { ...buildFetchUsetTokenHeaders(token), ...FetchJsonHeaders },
            body: JSON.stringify(data)
        })
        if (response.status === 204) {
            return true
        }

        return loginFailureHandle(await response.json())

    },

    /**
    * 放款列表
    * @param {*} param0  参数
    * @returns  结果
    */
    async getOrderPassList({ token = getUserToken(), data }) {
        const response = await fetch(API_HOST + '/admin/order/lendingPayOrderList', {
            method: 'POST',
            headers: { ...buildFetchUsetTokenHeaders(token), ...FetchJsonHeaders },
            body: JSON.stringify(data)
        })
        if (response.status === 204) {
            return true
        }

        return loginFailureHandle(await response.json())

    },

    /**
    * 正在打款列表
    * @param {*} param0  参数
    * @returns  结果
    */
    async getOrderWaitPaymentList({ token = getUserToken(), data }) {
        const response = await fetch(API_HOST + '/admin/order/printingPayOrderList', {
            method: 'POST',
            headers: { ...buildFetchUsetTokenHeaders(token), ...FetchJsonHeaders },
            body: JSON.stringify(data)
        })
        if (response.status === 204) {
            return true
        }

        return loginFailureHandle(await response.json())

    },

    /**
    * 已完成列表
    * @param {*} param0  参数
    * @returns  结果
    */
    async getOrderCompleteList({ token = getUserToken(), data }) {
        const response = await fetch(API_HOST + '/admin/order/completeOrderList', {
            method: 'POST',
            headers: { ...buildFetchUsetTokenHeaders(token), ...FetchJsonHeaders },
            body: JSON.stringify(data)
        })
        if (response.status === 204) {
            return true
        }

        return loginFailureHandle(await response.json())

    },
    /**
    * 已完成列表
    * @param {*} param0  参数
    * @returns  结果
    */
    async exportOrderCompleteList({ token = getUserToken(), data, isDownload }) {
        const response = await fetch(API_HOST + '/admin/order/completeOrderListExcel', {
            method: 'POST',
            headers: { ...buildFetchUsetTokenHeaders(token), ...FetchJsonHeaders },
            responseType: 'arraybuffer',
            body: JSON.stringify(data)
        })

        if (response.status === 204) {
            return true
        }

        const blob = await response.blob()

        if (isDownload) {
            blobDownloadFiles(blob, '已完成列表.xls')
        }

        return blob
    },
    /**
    * 已支付列表
    * @param {*} param0  参数
    * @returns  结果
    */
    async getOrderPaymentCompleteList({ token = getUserToken(), data }) {
        const response = await fetch(API_HOST + '/admin/order/allOrderList', {
            method: 'POST',
            headers: { ...buildFetchUsetTokenHeaders(token), ...FetchJsonHeaders },
            body: JSON.stringify(data)
        })
        if (response.status === 204) {
            return true
        }

        return loginFailureHandle(await response.json())

    },
    /**
    * 导出已支付列表
    * @param {*} param0  参数
    * @returns  结果
    */
    async exportOrderPaymentCompleteList({ token = getUserToken(), data, isDownload }) {
        const response = await fetch(API_HOST + '/admin/order/allOrderListExcel', {
            method: 'POST',
            headers: { ...buildFetchUsetTokenHeaders(token), ...FetchJsonHeaders },
            responseType: 'arraybuffer',
            body: JSON.stringify(data)
        })

        if (response.status === 204) {
            return true
        }

        const blob = await response.blob()

        if (isDownload) {
            blobDownloadFiles(blob, '今日展期.xls')
        }

        return blob

    },

    /**
    * 待还款列表
    * @param {*} param0  参数
    * @returns  结果
    */
    async getOrderReimbursementList({ token = getUserToken(), data }) {
        const response = await fetch(API_HOST + '/admin/order/forReimbursementOrderList', {
            method: 'POST',
            headers: { ...buildFetchUsetTokenHeaders(token), ...FetchJsonHeaders },
            body: JSON.stringify(data)
        })
        if (response.status === 204) {
            return true
        }

        return loginFailureHandle(await response.json())

    },

    /**
    * 导出待还款列表
    * @param {*} param0  参数
    * @returns  结果
    */
    async exportOrderReimbursementList({ token = getUserToken(), data, isDownload, fileName = '待还款列表' }) {
        const response = await fetch(API_HOST + '/admin/order/forReimbursementOrderListExcel', {
            method: 'POST',
            headers: { ...buildFetchUsetTokenHeaders(token), ...FetchJsonHeaders },
            responseType: 'arraybuffer',
            body: JSON.stringify(data)
        })

        if (response.status === 204) {
            return true
        }

        const blob = await response.blob()

        if (isDownload) {
            blobDownloadFiles(blob, `${fileName}.xls`)
        }

        return blob

    },

    /**
    * 订单审核
    * @param {*} param0  参数
    * @returns  结果
    */
    async auditOrder({ token = getUserToken(), data }) {
        const response = await fetch(API_HOST + '/admin/order/auditOrder', {
            method: 'POST',
            headers: { ...buildFetchUsetTokenHeaders(token), ...FetchJsonHeaders },
            body: JSON.stringify(data)
        })
        if (response.status === 204) {
            return true
        }

        return loginFailureHandle(await response.json())

    },
    /**
    * 减免还款金额
    * @param {*} param0  参数
    * @returns  结果
    */
    async derateAmount({ token = getUserToken(), data }) {
        const response = await fetch(API_HOST + '/admin/order/derateAmount', {
            method: 'POST',
            headers: { ...buildFetchUsetTokenHeaders(token), ...FetchJsonHeaders },
            body: JSON.stringify(data)
        })
        if (response.status === 204) {
            return true
        }

        return loginFailureHandle(await response.json())

    },
    /**
    * 订单延期
    * @param {*} param0  参数
    * @returns  结果
    */
    async orderDefer({ token = getUserToken(), data }) {
        const response = await fetch(API_HOST + '/admin/order/orderDefer', {
            method: 'POST',
            headers: { ...buildFetchUsetTokenHeaders(token), ...FetchJsonHeaders },
            body: JSON.stringify(data)
        })
        if (response.status === 204) {
            return true
        }

        return loginFailureHandle(await response.json())

    },
    /**
    * 修改为已打款状态
    * @param {*} param0  参数
    * @returns  结果
    */
    async remitOrder({ token = getUserToken(), data }) {
        const response = await fetch(API_HOST + '/admin/order/remitOrder', {
            method: 'POST',
            headers: { ...buildFetchUsetTokenHeaders(token), ...FetchJsonHeaders },
            body: JSON.stringify(data)
        })
        if (response.status === 204) {
            return true
        }

        return loginFailureHandle(await response.json())

    },
    /**
    * 还款
    * @param {*} param0  参数
    * @returns  结果
    */
    async repaymentOrder({ token = getUserToken(), data }) {
        const response = await fetch(API_HOST + '/admin/order/repaymentOrder', {
            method: 'POST',
            headers: { ...buildFetchUsetTokenHeaders(token), ...FetchJsonHeaders },
            body: JSON.stringify(data)
        })
        if (response.status === 204) {
            return true
        }

        return loginFailureHandle(await response.json())

    }
    ,
    /**
    * 改为分期订单
    * @param {*} param0  参数
    * @returns  结果
    */
    async uptAgingOrder({ token = getUserToken(), data }) {
        const response = await fetch(API_HOST + '/admin/order/uptAgingOrder', {
            method: 'POST',
            headers: { ...buildFetchUsetTokenHeaders(token), ...FetchJsonHeaders },
            body: JSON.stringify(data)
        })
        if (response.status === 204) {
            return true
        }

        return loginFailureHandle(await response.json())

    },
    /**
    * 查询软件版本列表
    * @param {*} param0  参数
    * @returns  结果
    */
    async getSoftVersionList({ token = getUserToken(), data }) {
        const response = await fetch(API_HOST + '/admin/version/getAppVersionList', {
            method: 'POST',
            headers: { ...buildFetchUsetTokenHeaders(token), ...FetchJsonHeaders },
            body: JSON.stringify(data)
        })
        if (response.status === 204) {
            return true
        }

        return loginFailureHandle(await response.json())

    },
    /**
     * 保存软件版本
     * @param {*} param0  参数
     * @returns  结果
     */
    async saveSoftVersion({ token = getUserToken(), data }) {
        const response = await fetch(API_HOST + '/admin/version/addAppVersion', {
            method: 'POST',
            headers: { ...buildFetchUsetTokenHeaders(token), ...FetchJsonHeaders },
            body: JSON.stringify(data)
        })
        if (response.status === 204) {
            return true
        }

        return loginFailureHandle(await response.json())

    },
    /**
    * 获取分期金额集
    * @param {*} param0  参数
    * @returns  结果
    */
    async getAgingOrderPrice({ token = getUserToken(), data }) {
        const response = await fetch(API_HOST + '/admin/order/getAgingOrderPrice', {
            method: 'POST',
            headers: { ...buildFetchUsetTokenHeaders(token), ...FetchJsonHeaders },
            body: JSON.stringify(data)
        })
        if (response.status === 204) {
            return true
        }

        return loginFailureHandle(await response.json())

    },

    /**
       * 获取订单详情
       * @param {*} param0  参数
       * @returns  结果
       */
    async getOrderInfo({ token = getUserToken(), orderId }) {
        const response = await fetch(API_HOST + `/admin/order/getOrderInfo/${orderId}`, {
            method: 'GET',
            headers: { ...buildFetchUsetTokenHeaders(token), ...FetchJsonHeaders },
        })
        if (response.status === 204) {
            return true
        }

        return loginFailureHandle(await response.json())
    },

    /**
      * 开放申请
      * @param {*} param0  参数
      * @returns  结果
      */
    async openApplyLoan({ token = getUserToken(), userId }) {
        const response = await fetch(API_HOST + `/admin/order/openApplayOrder/${userId}`, {
            method: 'GET',
            headers: { ...buildFetchUsetTokenHeaders(token), ...FetchJsonHeaders },
        })
        if (response.status === 204) {
            return true
        }

        return loginFailureHandle(await response.json())
    },

    /**
      * 注册未申请
      * @param {*} param0  参数
      * @returns  结果
      */
    async getNoLoanAfterRegistrationUser({ token = getUserToken(), data }) {
        const response = await fetch(API_HOST + '/admin/user/getNotApplyList', {
            method: 'POST',
            headers: { ...buildFetchUsetTokenHeaders(token), ...FetchJsonHeaders },
            body: JSON.stringify(data),
        })
        if (response.status === 204) {
            return true
        }

        return loginFailureHandle(await response.json())
    },

    /**
      * 正常还款未借款
      * @param {*} param0  参数
      * @returns  结果
      */
    async getNormalRepaymentNotApplyList({ token = getUserToken(), data }) {
        const response = await fetch(API_HOST + '/admin/user/getNormalRepaymentNotApplyList', {
            method: 'POST',
            headers: { ...buildFetchUsetTokenHeaders(token), ...FetchJsonHeaders },
            body: JSON.stringify(data),
        })
        if (response.status === 204) {
            return true
        }

        return loginFailureHandle(await response.json())
    },

    /**
      * 商户消费记录
      * @param {*} param0  参数
      * @returns  结果
      */
    async getMerchantBills({ token = getUserToken(), data }) {
        const response = await fetch(API_HOST + '/admin/merchant/getBill', {
            method: 'POST',
            headers: { ...buildFetchUsetTokenHeaders(token), ...FetchJsonHeaders },
            body: JSON.stringify(data),
        })
        if (response.status === 204) {
            return true
        }

        return loginFailureHandle(await response.json())
    },


    /**
     * 获取消费信息
     * @param {*} param0  参数
     * @returns  结果
     */
    async getPlatformStatistics({ token = getUserToken(), data }) {
        const response = await fetch(API_HOST + '/admin/merchant/getPlatformStatistics', {
            method: 'POST',
            headers: { ...buildFetchUsetTokenHeaders(token), ...FetchJsonHeaders },
            body: JSON.stringify(data),
        })
        if (response.status === 204) {
            return true
        }

        return loginFailureHandle(await response.json())
    },
    /**
     * 获取消费信息
     * @param {*} param0  参数
     * @returns  结果
     */
    async getPlatformMoney({ token = getUserToken() }) {
        const response = await fetch(API_HOST + '/admin/merchant/getPlatformMoney', {
            method: 'GET',
            headers: { ...buildFetchUsetTokenHeaders(token), ...FetchJsonHeaders },
        })
        if (response.status === 204) {
            return true
        }

        return loginFailureHandle(await response.json())
    },

    /**
    * 获取推广渠道
    * @param {*} param0  参数
    * @returns  结果
    */
    async getPromotionChannels({ token = getUserToken(), data }) {
        const response = await fetch(API_HOST + '/admin/channel/getList', {
            method: 'POST',
            headers: { ...buildFetchUsetTokenHeaders(token), ...FetchJsonHeaders },
            body: JSON.stringify(data),
        })
        if (response.status === 204) {
            return true
        }

        return loginFailureHandle(await response.json())
    },

    /**
    * 保存推广渠道
    * @param {*} param0  参数
    * @returns  结果
    */
    async savePromotionChannel({ token = getUserToken(), data }) {
        const response = await fetch(API_HOST + '/admin/channel/addAppVersion', {
            method: 'POST',
            headers: { ...buildFetchUsetTokenHeaders(token), ...FetchJsonHeaders },
            body: JSON.stringify(data),
        })
        if (response.status === 204) {
            return true
        }

        return loginFailureHandle(await response.json())
    },

    /**
    * 获取提额列表
    * @param {*} param0  参数
    * @returns  结果
    */
    async getPromoteQuotaList({ token = getUserToken(), data }) {
        const response = await fetch(API_HOST + '/admin/promote/getPromoteQuotaList', {
            method: 'POST',
            headers: { ...buildFetchUsetTokenHeaders(token), ...FetchJsonHeaders },
            body: JSON.stringify(data),
        })
        if (response.status === 204) {
            return true
        }

        return loginFailureHandle(await response.json())
    },

    /**
    * 保存提额列表
    * @param {*} param0  参数
    * @returns  结果
    */
    async savePromoteQuota({ token = getUserToken(), data }) {
        const response = await fetch(API_HOST + '/admin/promote/addPromoteQuota', {
            method: 'POST',
            headers: { ...buildFetchUsetTokenHeaders(token), ...FetchJsonHeaders },
            body: JSON.stringify(data),
        })
        if (response.status === 204) {
            return true
        }

        return loginFailureHandle(await response.json())
    },

    /**
    * 仪表盘统计
    * @param {*} param0  参数
    * @returns  结果
    */
    async getGlobalStatisticsList({ token = getUserToken() }) {
        const response = await fetch(API_HOST + '/admin/statistics/getGlobalConfigList', {
            method: 'GET',
            headers: { ...buildFetchUsetTokenHeaders(token), ...FetchJsonHeaders },
        })
        if (response.status === 204) {
            return true
        }

        return loginFailureHandle(await response.json())
    },

    /**
      * 上传文件
      * @param {*} param0  参数
      * @returns  结果
      */
    async uploadFile({ token = getUserToken(), file }) {
        const formData = new FormData()
        formData.append('file', file)

        const response = await fetch(API_HOST + '/admin/config/uploadFile', {
            method: 'POST',
            headers: { ...buildFetchUsetTokenHeaders(token) },
            body: formData
        })
        if (response.status === 204) {
            return true
        }

        return loginFailureHandle(await response.json())
    },
    /**
      * 导入白名单
      * @param {*} param0  参数
      * @returns  结果
      */
    async importWhitelist({ token = getUserToken(), file }) {
        const formData = new FormData()
        formData.append('file', file)

        const response = await fetch(API_HOST + '/admin/white/mobile/import', {
            method: 'POST',
            headers: { ...buildFetchUsetTokenHeaders(token) },
            body: formData
        })
        if (response.status === 204) {
            return true
        }

        return loginFailureHandle(await response.json())
    },

    /**
      * 导入白名单V2
      * @param {*} param0  参数
      * @returns  结果
      */
    async importWhitelistV2({ token = getUserToken(), file }) {
        const formData = new FormData()
        formData.append('excel', file)

        const response = await fetch(API_HOST_IMPORT + '/import', {
            method: 'POST',
            headers: { ...buildFetchUsetTokenHeaders(token) },
            body: formData,
        })
        if (response.status === 204) {
            return true
        }

        return loginFailureHandle(await response.json())
    },

    /**
      * 导入白名单
      * @param {*} param0  参数
      * @returns  结果
      */
    async importRiskBatchProcessing({ token = getUserToken(), file }) {
        const formData = new FormData()
        formData.append('file', file)

        const response = await fetch(API_HOST + '/admin/batch/risk/import', {
            method: 'POST',
            headers: { ...buildFetchUsetTokenHeaders(token) },
            body: formData
        })
        if (response.status === 204) {
            return true
        }

        return loginFailureHandle(await response.json())
    },

    /**
    * 修改额度
    * @param {*} param0  参数
    * @returns  结果
    */
    async updateUserQuota({ token = getUserToken(), userId, quota }) {
        const response = await fetch(API_HOST + `/admin/user/${userId}/quota_${quota}`, {
            method: 'POST',
            headers: { ...buildFetchUsetTokenHeaders(token), ...FetchJsonHeaders },
        })
        if (response.status === 204) {
            return true
        }

        return loginFailureHandle(await response.json())
    },
    /**
     * 修改额度
     * @param {*} param0  参数
     * @returns  结果
    */
    async uptOrderQuota({ token = getUserToken(), data }) {
        const response = await fetch(API_HOST + '/admin/order/uptOrderQuota', {
            method: 'POST',
            headers: { ...buildFetchUsetTokenHeaders(token), ...FetchJsonHeaders },
            body: JSON.stringify(data)
        })

        if (response.status === 204) {
            return true
        }

        return loginFailureHandle(await response.json())
    },

    /**
     * 获取用户登陆验证码
     * @param {*} param0  参数
     * @returns  结果
    */
    async getUserCode({ token = getUserToken(), userId }) {
        const response = await fetch(API_HOST + `/admin/index/getUserCode/${userId}`, {
            method: 'GET',
            headers: { ...buildFetchUsetTokenHeaders(token), ...FetchJsonHeaders },
        })

        if (response.status === 204) {
            return true
        }

        return loginFailureHandle(await response.json())
    }
    ,

    /**
     * 获取登陆验证码
     * @param {*} param0  参数
     * @returns  结果
    */
    async getCodeByMobile({ token = getUserToken(), mobile }) {
        const response = await fetch(API_HOST + `/admin/index/getCodeByMobile/${mobile}`, {
            method: 'GET',
            headers: { ...buildFetchUsetTokenHeaders(token), ...FetchJsonHeaders },
        })

        if (response.status === 204) {
            return true
        }

        return loginFailureHandle(await response.json())
    },

    /**
     * 获取渠道统计
     * @param {*} param0  参数
     * @returns  结果
    */
    async getH5RiskStatistics({ token = getUserToken(), query }) {
        const response = await fetch(API_HOST + '/admin/h5/risk/getChannelStatistics', {
            method: 'POST',
            headers: { ...buildFetchUsetTokenHeaders(token), ...FetchJsonHeaders },
            body: JSON.stringify(query)
        })

        if (response.status === 204) {
            return true
        }

        return loginFailureHandle(await response.json())
    },

    /**
     * 获取渠道统计
     * @param {*} param0  参数
     * @returns  结果
    */
    async getChannelStatistics({ token = getUserToken(), query }) {
        const response = await fetch(API_HOST + '/admin/channel/getChannelStatistics', {
            method: 'POST',
            headers: { ...buildFetchUsetTokenHeaders(token), ...FetchJsonHeaders },
            body: JSON.stringify(query)
        })

        if (response.status === 204) {
            return true
        }

        return loginFailureHandle(await response.json())
    },

    /**
     * 获取渠道统计
     * @param {*} param0  参数
     * @returns  结果
    */
    async getAdminUserRisk({ token = getUserToken(), query }) {
        const response = await fetch(API_HOST + '/admin/user/risk/getList', {
            method: 'POST',
            headers: { ...buildFetchUsetTokenHeaders(token), ...FetchJsonHeaders },
            body: JSON.stringify(query)
        })

        if (response.status === 204) {
            return true
        }

        return loginFailureHandle(await response.json())
    },



    /**
     * 添加用户信息已查询风控
     * @param {*} param0  参数
     * @returns  结果
    */
    async addAdminUserRisk({ token = getUserToken(), form }) {
        const response = await fetch(API_HOST + '/admin/user/risk/addAdminUserRisk', {
            method: 'POST',
            headers: { ...buildFetchUsetTokenHeaders(token), ...FetchJsonHeaders },
            body: JSON.stringify(form)
        })

        if (response.status === 204) {
            return true
        }

        return loginFailureHandle(await response.json())
    }

    ,



    /**
     * 探针C刷新
     * @param {*} param0  参数
     * @returns  结果
    */
    async adminUserRiskCRefresh({ token = getUserToken(), userId }) {
        const response = await fetch(API_HOST + '/admin/user/risk/tanzhenC', {
            method: 'POST',
            headers: { ...buildFetchUsetTokenHeaders(token), ...FetchJsonHeaders },
            body: JSON.stringify({ userId })
        })

        if (response.status === 204) {
            return true
        }

        return loginFailureHandle(await response.json())
    },
    /**
     * 雷达报告刷新
     * @param {*} param0  参数
     * @returns  结果
    */
    async adminUserRiskRadarRefresh({ token = getUserToken(), userId }) {
        const response = await fetch(API_HOST + '/admin/user/risk/leida', {
            method: 'POST',
            headers: { ...buildFetchUsetTokenHeaders(token), ...FetchJsonHeaders },
            body: JSON.stringify({ userId })
        })

        if (response.status === 204) {
            return true
        }

        return loginFailureHandle(await response.json())
    }
    ,



    /**
     * 获取探针C数据
     * @param {*} param0  参数
     * @returns  结果
    */
    async getAdminUserRiskC({ token = getUserToken(), userId }) {
        const response = await fetch(API_HOST + `/admin/user/risk/getTanzhenCByUserId/${userId}`, {
            method: 'POST',
            headers: { ...buildFetchUsetTokenHeaders(token), ...FetchJsonHeaders },
        })

        if (response.status === 204) {
            return true
        }

        return loginFailureHandle(await response.json())
    }
    ,
    /**
     * 获取雷达数据
     * @param {*} param0  参数
     * @returns  结果
    */
    async getAdminUserRiskRadar({ token = getUserToken(), userId }) {
        const response = await fetch(API_HOST + `/admin/user/risk/getLeidaByUserId/${userId}`, {
            method: 'POST',
            headers: { ...buildFetchUsetTokenHeaders(token), ...FetchJsonHeaders },
        })

        if (response.status === 204) {
            return true
        }

        return loginFailureHandle(await response.json())
    }
    ,
    /**
     * 获取雷达数据实时
     * @param {*} param0  参数
     * @returns  结果
    */
    async getRealTimeRiskRadar({ token = getUserToken(), param }) {
        const response = await fetch(API_HOST + '/admin/other/risk/leida', {
            method: 'POST',
            headers: { ...buildFetchUsetTokenHeaders(token), ...FetchJsonHeaders },
            body: JSON.stringify(param)
        })

        if (response.status === 204) {
            return true
        }

        return loginFailureHandle(await response.json())
    },
    /**
    * 获取探针C数据实时
    * @param {*} param0  参数
    * @returns  结果
   */
    async getRealTimeRiskC({ token = getUserToken(), param }) {
        const response = await fetch(API_HOST + '/admin/other/risk/tanzhenC', {
            method: 'POST',
            headers: { ...buildFetchUsetTokenHeaders(token), ...FetchJsonHeaders },
            body: JSON.stringify(param)
        })

        if (response.status === 204) {
            return true
        }

        return loginFailureHandle(await response.json())
    },

    /**
    * 分页获取账单
    * @param {*} param0  参数
    * @returns  结果
   */
    async getRiskBillList({ token = getUserToken(), param }) {
        const response = await fetch(API_HOST + '/admin/user/risk/getBillList', {
            method: 'POST',
            headers: { ...buildFetchUsetTokenHeaders(token), ...FetchJsonHeaders },
            body: JSON.stringify(param)
        })

        if (response.status === 204) {
            return true
        }

        return loginFailureHandle(await response.json())
    },
    /**
    * 已完成用户订单
    * @param {*} param0  参数
    * @returns  结果
   */
    async getCompletedUsersOrder({ token = getUserToken(), data }) {
        const response = await fetch(API_HOST + '/admin/order/getCompletedUsers', {
            method: 'POST',
            headers: { ...buildFetchUsetTokenHeaders(token), ...FetchJsonHeaders },
            body: JSON.stringify(data)
        })

        if (response.status === 204) {
            return true
        }

        return loginFailureHandle(await response.json())
    },

    /**
     * 导出已完成用户订单
     * @param {*} param0  参数
     * @returns  结果
     */
    async exportCompletedUsersOrder({ token = getUserToken(), data, isDownload }) {
        const response = await fetch(API_HOST + '/admin/order/getCompletedUsersExcel', {
            method: 'POST',
            headers: { ...buildFetchUsetTokenHeaders(token), ...FetchJsonHeaders },
            responseType: 'arraybuffer',
            body: JSON.stringify(data)
        })

        if (response.status === 204) {
            return true
        }

        const blob = await response.blob()

        if (isDownload) {
            blobDownloadFiles(blob, '已完成用户订单.xls')
        }

        return blob
    },
    /**
    * 今天延期
    * @param {*} param0  参数
    * @returns  结果
   */
    async todayDeferOrderList({ token = getUserToken(), data }) {
        const response = await fetch(API_HOST + '/admin/order/todayDefer', {
            method: 'POST',
            headers: { ...buildFetchUsetTokenHeaders(token), ...FetchJsonHeaders },
            body: JSON.stringify(data)
        })

        if (response.status === 204) {
            return true
        }

        return loginFailureHandle(await response.json())
    },

    /**
    * 今天完成订单
    * @param {*} param0  参数
    * @returns  结果
   */
    async todayCompleteOrderListVO({ token = getUserToken(), data }) {
        const response = await fetch(API_HOST + '/admin/order/todayCompleteOrderListVO', {
            method: 'POST',
            headers: { ...buildFetchUsetTokenHeaders(token), ...FetchJsonHeaders },
            body: JSON.stringify(data)
        })

        if (response.status === 204) {
            return true
        }

        return loginFailureHandle(await response.json())
    },

    /**
    * 获取用户短信详情
    * @param {*} param0  参数
    * @returns  结果
   */
    async getUserSmsDataList({ token = getUserToken(), data }) {
        const response = await fetch(API_HOST + '/admin/auth/list/getUserSmsDataList', {
            method: 'POST',
            headers: { ...buildFetchUsetTokenHeaders(token), ...FetchJsonHeaders },
            body: JSON.stringify(data)
        })

        if (response.status === 204) {
            return true
        }

        return loginFailureHandle(await response.json())
    },

    /**
    * 首借逾期导出
    * @param {*} param0  参数
    * @returns  结果
   */
    async newOverExportExcel({ token = getUserToken(), data, isDownload }) {
        const response = await fetch(API_HOST + '/admin/order/newOverExcel', {
            method: 'POST',
            headers: { ...buildFetchUsetTokenHeaders(token), ...FetchJsonHeaders },
            body: JSON.stringify(data),
            responseType: 'arraybuffer',
        })

        if (response.status === 204) {
            return true
        }

        const blob = await response.blob()

        if (isDownload) {
            blobDownloadFiles(blob, '首借逾期订单.xls')
        }

        return blob
    },
    /**
    * 逾期列表导出
    * @param {*} param0  参数
    * @returns  结果
   */
    async loanOverdueExportExcel({ token = getUserToken(), data, isDownload }) {
        const response = await fetch(API_HOST + '/admin/order/getOverOrderExcel', {
            method: 'POST',
            headers: { ...buildFetchUsetTokenHeaders(token), ...FetchJsonHeaders },
            body: JSON.stringify(data),
            responseType: 'arraybuffer',
        })

        if (response.status === 204) {
            return true
        }

        const blob = await response.blob()

        if (isDownload) {
            blobDownloadFiles(blob, '逾期订单.xls')
        }

        return blob
    },

    /**
    * 今日到期导出
    * @param {*} param0  参数
    * @returns  结果
   */
    async todayExpireExportExcel({ token = getUserToken(), data, isDownload }) {
        const response = await fetch(API_HOST + '/admin/order/todayDaoqiExcel', {
            method: 'POST',
            headers: { ...buildFetchUsetTokenHeaders(token), ...FetchJsonHeaders },
            body: JSON.stringify(data),
            responseType: 'arraybuffer',
        })

        if (response.status === 204) {
            return true
        }

        const blob = await response.blob()

        if (isDownload) {
            blobDownloadFiles(blob, '今日到期订单.xls')
        }

        return blob
    },

    /**
    * 复借逾期导出
    * @param {*} param0  参数
    * @returns  结果
   */
    async oldOverExcelExportExcel({ token = getUserToken(), data, isDownload }) {
        const response = await fetch(API_HOST + '/admin/order/oldOverExcel', {
            method: 'POST',
            headers: { ...buildFetchUsetTokenHeaders(token), ...FetchJsonHeaders },
            body: JSON.stringify(data),
            responseType: 'arraybuffer',
        })

        if (response.status === 204) {
            return true
        }

        const blob = await response.blob()

        if (isDownload) {
            blobDownloadFiles(blob, '复借逾期订单.xls')
        }

        return blob
    },

    /**
    * 明日到期导出
    * @param {*} param0  参数
    * @returns  结果
   */
    async tomorrowExpireExportExcel({ token = getUserToken(), data, isDownload }) {
        const response = await fetch(API_HOST + '/admin/order/tomorrowDaoqiExcel', {
            method: 'POST',
            headers: { ...buildFetchUsetTokenHeaders(token), ...FetchJsonHeaders },
            body: JSON.stringify(data),
            responseType: 'arraybuffer',
        })

        if (response.status === 204) {
            return true
        }

        const blob = await response.blob()

        if (isDownload) {
            blobDownloadFiles(blob, '明日到期订单.xls')
        }

        return blob
    },

    /**
    * 今天延期
    * @param {*} param0  参数
    * @returns  结果
   */
    async addOrderRemarks({ token = getUserToken(), id, value }) {
        const response = await fetch(API_HOST + `/admin/remark/addRemark?orderId=${id}&context=${value}`, {
            method: 'POST',
            headers: { ...buildFetchUsetTokenHeaders(token), ...FetchJsonHeaders },
        })

        if (response.status === 204) {
            return true
        }

        return loginFailureHandle(await response.json())
    },

    /**
    * 待还款列表
    * @param {*} param0  参数
    * @returns  结果
    */
    async todayForReimbursementOrderList({ token = getUserToken(), data }) {
        data.becomeDueUserId = data.manageId;
        data.becomeIsAllocation = data.isAllocation;
        data.overDueUserId = data.manageId;
        data.overIsAllocation = data.isAllocation;

        const response = await fetch(API_HOST + '/admin/order/todayForReimbursementOrderList', {
            method: 'POST',
            headers: { ...buildFetchUsetTokenHeaders(token), ...FetchJsonHeaders },
            body: JSON.stringify(data)
        })
        if (response.status === 204) {
            return true
        }

        return loginFailureHandle(await response.json())

    },

    /**
   * 渠道日统计
   * @param {*} param0  参数
   * @returns  结果
   */
    async getChannelStatisticsByDay({ token = getUserToken(), query }) {
        const response = await fetch(API_HOST + '/admin/channel/getChannelStatisticsByDay', {
            method: 'POST',
            headers: { ...buildFetchUsetTokenHeaders(token), ...FetchJsonHeaders },
            body: JSON.stringify(query)
        })
        if (response.status === 204) {
            return true
        }

        return loginFailureHandle(await response.json())

    },
    /**
   * 渠道日统计
   * @param {*} param0  参数
   * @returns  结果
   */
    async getH5RiskStatisticsByDay({ token = getUserToken(), query }) {
        const response = await fetch(API_HOST + '/admin/h5/risk/getChannelStatisticsByDay', {
            method: 'POST',
            headers: { ...buildFetchUsetTokenHeaders(token), ...FetchJsonHeaders },
            body: JSON.stringify(query)
        })
        if (response.status === 204) {
            return true
        }

        return loginFailureHandle(await response.json())

    },
    /**
     * 已还款还款
     * @param {*} param0  参数
     * @returns  结果
     */
    async getRepaymentStatistics({ token = getUserToken(), query }) {
        const response = await fetch(API_HOST + '/admin/order/sta/getOverOrderStatistics', {
            method: 'POST',
            headers: { ...buildFetchUsetTokenHeaders(token), ...FetchJsonHeaders },
            body: JSON.stringify(query)
        })
        if (response.status === 204) {
            return true
        }

        return loginFailureHandle(await response.json())

    },

    /**
     * 获取进行中订单&还款金额&逾期金额
     * @param {*} param0  参数
     * @returns  结果
     */
    async getUnderwayOrderMoney({ token = getUserToken(), orderId }) {
        const response = await fetch(API_HOST + `/admin/order/getUnderwayOrderMoney/${orderId}`, {
            method: 'POST',
            headers: { ...buildFetchUsetTokenHeaders(token), ...FetchJsonHeaders },
        })
        if (response.status === 204) {
            return true
        }

        return loginFailureHandle(await response.json())

    },

    /**
      * 获取贷超轮播图列表
      * @param {*} param0  参数
      * @returns  结果
      */
    async getLoanSupermarket({ token = getUserToken(), param }) {
        const response = await fetch(API_HOST + '/admin/coles/slideshow/getList', {
            method: 'POST',
            headers: { ...buildFetchUsetTokenHeaders(token), ...FetchJsonHeaders },
            body: JSON.stringify(param)
        })
        if (response.status === 204) {
            return true
        }

        return loginFailureHandle(await response.json())

    },

    /**
      * 添加贷超轮播图列表
      * @param {*} param0  参数
      * @returns  结果
      */
    async saveLoanSupermarket({ token = getUserToken(), param }) {
        const response = await fetch(API_HOST + '/admin/coles/slideshow/addColesSlideshow', {
            method: 'POST',
            headers: { ...buildFetchUsetTokenHeaders(token), ...FetchJsonHeaders },
            body: JSON.stringify(param)
        })
        if (response.status === 204) {
            return true
        }

        return loginFailureHandle(await response.json())

    },

    /**
      * 添加贷超轮播图列表
      * @param {*} param0  参数
      * @returns  结果
      */
    async delLoanSupermarket({ token = getUserToken(), id }) {
        const response = await fetch(API_HOST + `/admin/coles/slideshow/removeById?id=${id}`, {
            method: 'POST',
            headers: { ...buildFetchUsetTokenHeaders(token), ...FetchJsonHeaders },
        })
        if (response.status === 204) {
            return true
        }

        return loginFailureHandle(await response.json())

    },

    /**
      * 获取贷超数据列表
      * @param {*} param0  参数
      * @returns  结果
      */
    async getLoanSupermarketData({ token = getUserToken(), param }) {
        const response = await fetch(API_HOST + '/admin/coles/data/getList', {
            method: 'POST',
            headers: { ...buildFetchUsetTokenHeaders(token), ...FetchJsonHeaders },
            body: JSON.stringify(param)
        })
        if (response.status === 204) {
            return true
        }

        return loginFailureHandle(await response.json())

    },

    /**
      * 添加贷超数据列表
      * @param {*} param0  参数
      * @returns  结果
      */
    async saveLoanSupermarketData({ token = getUserToken(), param }) {
        const response = await fetch(API_HOST + '/admin/coles/data/addColesData', {
            method: 'POST',
            headers: { ...buildFetchUsetTokenHeaders(token), ...FetchJsonHeaders },
            body: JSON.stringify(param)
        })
        if (response.status === 204) {
            return true
        }

        return loginFailureHandle(await response.json())

    },

    /**
      * 添加贷超数据列表
      * @param {*} param0  参数
      * @returns  结果
      */
    async delLoanSupermarketData({ token = getUserToken(), id }) {
        const response = await fetch(API_HOST + `/admin/coles/data/removeById?id=${id}`, {
            method: 'POST',
            headers: { ...buildFetchUsetTokenHeaders(token), ...FetchJsonHeaders },
        })
        if (response.status === 204) {
            return true
        }

        return loginFailureHandle(await response.json())

    },

    /**
      * 密码登录
      * @param {*} param0  参数
      * @returns  结果
      */
    async loginByPassword(param) {
        const response = await fetch(API_HOST + '/admin/index/loginByPassword', {
            method: 'POST',
            headers: { ...FetchJsonHeaders },
            body: JSON.stringify(param)
        })
        if (response.status === 204) {
            return true
        }

        return loginFailureHandle(await response.json())

    },

    /**
     * 手机白名单
     */
    async getMobileWhitelist({ token = getUserToken(), param }) {
        const response = await fetch(API_HOST + '/admin/white/mobile/getList', {
            method: 'POST',
            headers: { ...buildFetchUsetTokenHeaders(token), ...FetchJsonHeaders },
            body: JSON.stringify(param)
        })
        if (response.status === 204) {
            return true
        }

        return loginFailureHandle(await response.json())
    },

    /**
     * 重置密登录码
     */
    async resetLoginPassword({ token = getUserToken(), param }) {
        const response = await fetch(API_HOST + `/admin/index/uptPassword?${qs.stringify(param)}`, {
            method: 'GET',
            headers: { ...buildFetchUsetTokenHeaders(token), ...FetchJsonHeaders },
        })
        if (response.status === 204) {
            return true
        }

        return loginFailureHandle(await response.json())
    },

    /**
     * 获取用户APP应用信息
     * @param {*} param0  参数
     * @returns  结果
     */
    async getUserAppDataList({ token = getUserToken(), param }) {
        const response = await fetch(API_HOST + '/admin/auth/list/getUserAppDataList', {
            method: 'POST',
            headers: { ...buildFetchUsetTokenHeaders(token), ...FetchJsonHeaders },
            body: JSON.stringify(param)
        })
        if (response.status === 204) {
            return true
        }

        return loginFailureHandle(await response.json())

    },

    /**
     * 单独获取米融QTZ
     * @param {*} param0  参数
     * @returns  结果
     */
    async refreshQtzByUserId({ token = getUserToken(), userId }) {
        const response = await fetch(API_HOST + `/admin/risk/getQtzByUserId/${userId}`, {
            method: 'POST',
            headers: { ...buildFetchUsetTokenHeaders(token), ...FetchJsonHeaders },
        })
        if (response.status === 204) {
            return true
        }

        return loginFailureHandle(await response.json())

    },

    /**
     * 单独获取米融QTZ
     * @param {*} param0  参数
     * @returns  结果
     */
    async getQtzByUserId({ token = getUserToken(), userId }) {
        const response = await fetch(API_HOST + '/admin/risk/qtz', {
            method: 'POST',
            headers: { ...buildFetchUsetTokenHeaders(token), ...FetchJsonHeaders },
            body: JSON.stringify({ userId })
        })
        if (response.status === 204) {
            return true
        }

        return loginFailureHandle(await response.json())

    },

    /**
     * 获取进行中订单 待还款分期金额列表
     * @param {*} param0  参数
     * @returns  结果
     */
    async getUnderwayOrderFqMoney({ token = getUserToken(), orderId }) {
        const response = await fetch(API_HOST + `/admin/order/getUnderwayOrderFqMoney/${orderId}`, {
            method: 'GET',
            headers: { ...buildFetchUsetTokenHeaders(token), ...FetchJsonHeaders },
        })
        if (response.status === 204) {
            return true
        }

        return loginFailureHandle(await response.json())

    },

    /**
     * 修改进行中订单 待还款分期金额列表
     * @param {*} param0  参数
     * @returns  结果
     */
    async saveUnderwayOrderFqMoney({ token = getUserToken(), param }) {
        const response = await fetch(API_HOST + '/admin/order/uptUnderwayOrderFqMoney', {
            method: 'POST',
            headers: { ...buildFetchUsetTokenHeaders(token), ...FetchJsonHeaders },
            body: JSON.stringify(param)
        })
        if (response.status === 204) {
            return true
        }

        return loginFailureHandle(await response.json())

    },

    /**
    * 获取米融共同债务
    * @param {*} param0  参数
    * @returns  结果
    */
    async getMiRongGZ({ token = getUserToken(), userId }) {
        const response = await fetch(API_HOST + '/admin/risk/whgz', {
            method: 'POST',
            headers: { ...buildFetchUsetTokenHeaders(token), ...FetchJsonHeaders },
            body: JSON.stringify({ userId })
        })
        if (response.status === 204) {
            return true
        }

        return loginFailureHandle(await response.json())
    },
    /**
    * 获取米融共同债务(内部)
    * @param {*} param0  参数
    * @returns  结果
    */
    async getMiRongGZV2({ token = getUserToken(), userId }) {
        const response = await fetch(API_HOST + `/admin/risk/getInteriorGzByUserId/${userId}`, {
            method: 'POST',
            headers: { ...buildFetchUsetTokenHeaders(token), ...FetchJsonHeaders },
        })
        if (response.status === 204) {
            return true
        }

        return loginFailureHandle(await response.json())
    },

    /**
    * 刷新米融共同债务
    * @param {*} param0  参数
    * @returns  结果
    */
    async refreshMiRongGZ({ token = getUserToken(), userId, refresh = false }) {
        const response = await fetch(API_HOST + `/admin/risk/getWhgzByUserId/${userId}?refresh=${refresh}`, {
            method: 'POST',
            headers: { ...buildFetchUsetTokenHeaders(token), ...FetchJsonHeaders },
        })
        if (response.status === 204) {
            return true
        }

        return loginFailureHandle(await response.json())
    },

    /**
    * 批量分配提醒/批量领取
    * @param {*} param0  参数
    * @returns  结果
    */
    async batchAllocationOrder({ token = getUserToken(), data }) {
        const response = await fetch(API_HOST + '/admin/reinsurance/policy/batchAllocationOrder', {
            method: 'POST',
            headers: { ...buildFetchUsetTokenHeaders(token), ...FetchJsonHeaders },
            body: JSON.stringify(data)
        })
        if (response.status === 204) {
            return true
        }

        return loginFailureHandle(await response.json())
    },

    /**
    * 批量分配逾期催单分配
    * @param {*} param0  参数
    * @returns  结果
    */
    async batchAllocationOverOrder({ token = getUserToken(), data }) {
        const response = await fetch(API_HOST + '/admin/reinsurance/policy/batchAllocationOverOrder', {
            method: 'POST',
            headers: { ...buildFetchUsetTokenHeaders(token), ...FetchJsonHeaders },
            body: JSON.stringify(data)
        })
        if (response.status === 204) {
            return true
        }

        return loginFailureHandle(await response.json())
    },

    /**
    * 获取米融多头B
    * @param {*} param0  参数
    * @returns  结果
    */
    async getMiRongDuoTouB({ token = getUserToken(), userId }) {
        const response = await fetch(API_HOST + '/admin/risk/tlx', {
            method: 'POST',
            headers: { ...buildFetchUsetTokenHeaders(token), ...FetchJsonHeaders },
            body: JSON.stringify({ userId })
        })
        if (response.status === 204) {
            return true
        }

        return loginFailureHandle(await response.json())
    },

    /**
     * 刷新米融多头B
     * @param {*} param0  参数
     * @returns  结果
     */
    async refreshMiRongDuoTouB({ token = getUserToken(), userId, refresh = false }) {
        const response = await fetch(API_HOST + `/admin/risk/getTlxByUserId/${userId}?refresh=${refresh}`, {
            method: 'POST',
            headers: { ...buildFetchUsetTokenHeaders(token), ...FetchJsonHeaders },
        })
        if (response.status === 204) {
            return true
        }

        return loginFailureHandle(await response.json())
    },

    /**
     * 获取信用分数集
     * @param {*} param0  参数
     * @returns  结果
     */
    async getMiRongScore({ token = getUserToken(), userId }) {
        const response = await fetch(API_HOST + '/admin/risk/getAllScore', {
            method: 'POST',
            headers: { ...buildFetchUsetTokenHeaders(token), ...FetchJsonHeaders },
            body: JSON.stringify({ userId })
        })
        if (response.status === 204) {
            return true
        }

        return loginFailureHandle(await response.json())
    },

    /**
     * 刷新小贷分
     * @param {*} param0  参数
     * @returns  结果
     */
    async refreshMiRongSmallLoanPointsScore({ token = getUserToken(), userId, refresh = false }) {
        const response = await fetch(API_HOST + `/admin/risk/getXdScore/${userId}?refresh=${refresh}`, {
            method: 'POST',
            headers: { ...buildFetchUsetTokenHeaders(token), ...FetchJsonHeaders },
        })
        if (response.status === 204) {
            return true
        }

        return loginFailureHandle(await response.json())
    },

    /**
     * 刷新反欺诈分
     * @param {*} param0  参数
     * @returns  结果
     */
    async refreshMiRongFraudScore({ token = getUserToken(), userId, refresh = false }) {
        const response = await fetch(API_HOST + `/admin/risk/getFzScore/${userId}?refresh=${refresh}`, {
            method: 'POST',
            headers: { ...buildFetchUsetTokenHeaders(token), ...FetchJsonHeaders },
        })
        if (response.status === 204) {
            return true
        }

        return loginFailureHandle(await response.json())
    },

    /**
     * 刷新米融黑名单是否命中
     * @param {*} param0  参数
     * @returns  结果
     */
    async refreshMiRongBKStatus({ token = getUserToken(), userId, refresh = false }) {
        const response = await fetch(API_HOST + `/admin/risk/getWhBlickListStatus/${userId}?refresh=${refresh}`, {
            method: 'POST',
            headers: { ...buildFetchUsetTokenHeaders(token), ...FetchJsonHeaders },
        })
        if (response.status === 204) {
            return true
        }

        return loginFailureHandle(await response.json())
    },

    /**
    * 刷新大金融黑名单是否命中
    * @param {*} param0  参数
    * @returns  结果
    */
    async refreshDaRongBKStatus({ token = getUserToken(), userId, refresh = false }) {
        const response = await fetch(API_HOST + `/admin/risk/getBuerBlickListStatus/${userId}?refresh=${refresh}`, {
            method: 'POST',
            headers: { ...buildFetchUsetTokenHeaders(token), ...FetchJsonHeaders },
        })
        if (response.status === 204) {
            return true
        }

        return loginFailureHandle(await response.json())
    },

    /**
    * 修改进行中订单 应还款时间
    * @param {*} param0  参数
    * @returns  结果
    */
    async uptOrderRepayTime({ token = getUserToken(), orderDetailId, time }) {
        const response = await fetch(API_HOST + `/admin/order/uptOrderTime?orderDetailId=${orderDetailId}&time=${moment(time).format('YYYY-MM-DD')}`, {
            method: 'POST',
            headers: { ...buildFetchUsetTokenHeaders(token), ...FetchJsonHeaders },
        })
        if (response.status === 204) {
            return true
        }

        return loginFailureHandle(await response.json())

    },

    /**
    * 拒绝单拉回待审核
    * @param {*} param0  参数
    * @returns  结果
    */
    async reAuditLoanOrder({ token = getUserToken(), orderId }) {
        const response = await fetch(API_HOST + `/admin/order/uptOrderStatus?orderId=${orderId}`, {
            method: 'POST',
            headers: { ...buildFetchUsetTokenHeaders(token), ...FetchJsonHeaders },
        })
        if (response.status === 204) {
            return true
        }

        return loginFailureHandle(await response.json())

    },

    /**
    * 获取用户运营商朋友圈信息
    * @param {*} param0  参数
    * @returns  结果
    */
    async getMobileFriendData({ token = getUserToken(), userId }) {
        const response = await fetch(API_HOST + `/admin/mobile/getMobileFriendData/${userId}`, {
            method: 'GET',
            headers: { ...buildFetchUsetTokenHeaders(token), ...FetchJsonHeaders },
        })
        if (response.status === 204) {
            return true
        }

        return loginFailureHandle(await response.json())

    },

    /**
     * 短信模糊查询关键字
     * @param {*} param0 参数
     * @returns 结果
     */
    async getSMSFindKeys({ token = getUserToken(), userId }) {
        const response = await fetch(API_HOST + `/admin/auth/list/getUserSmsKey/${userId}`, {
            method: 'GET',
            headers: { ...buildFetchUsetTokenHeaders(token), ...FetchJsonHeaders },
        })
        if (response.status === 204) {
            return true
        }

        return loginFailureHandle(await response.json())

    },
    /**
     * 分期改为正常订单
     * @param {*} param0 参数
     * @returns 结果
     */
    async uptOrderToOnly({ token = getUserToken(), param }) {
        const response = await fetch(API_HOST + '/admin/order/uptOrderToOnly', {
            method: 'POST',
            headers: { ...buildFetchUsetTokenHeaders(token), ...FetchJsonHeaders },
            body: JSON.stringify(param)
        })
        if (response.status === 204) {
            return true
        }

        return loginFailureHandle(await response.json())

    },

    /**
     * 获取交易流水
     * @param {*} param0 参数
     * @returns 结果
     */
    async fetchTransBillList({ token = getUserToken(), param }) {
        const response = await fetch(API_HOST + '/admin/amount/bill/getList', {
            method: 'POST',
            headers: { ...buildFetchUsetTokenHeaders(token), ...FetchJsonHeaders },
            body: JSON.stringify(param)
        })
        if (response.status === 204) {
            return true
        }

        return loginFailureHandle(await response.json())

    },

    /**
     * 获取短信流水
     * @param {*} param0 参数
     * @returns 结果
     */
    async fetchSmsBillList({ token = getUserToken(), param }) {
        const response = await fetch(API_HOST + '/admin/amount/bill/getSmsList', {
            method: 'POST',
            headers: { ...buildFetchUsetTokenHeaders(token), ...FetchJsonHeaders },
            body: JSON.stringify(param)
        })
        if (response.status === 204) {
            return true
        }

        return loginFailureHandle(await response.json())

    },

    /**
     * 获取交易流水统计
     * @param {*} param0 参数
     * @returns 结果
     */
    async fetchTransBillStatistics({ token = getUserToken(), param }) {
        const response = await fetch(API_HOST + '/admin/amount/bill/getTjList', {
            method: 'POST',
            headers: { ...buildFetchUsetTokenHeaders(token), ...FetchJsonHeaders },
            body: JSON.stringify(param)
        })
        if (response.status === 204) {
            return true
        }

        return loginFailureHandle(await response.json())

    },


    /**
     * 联系客服列表
     * @param {*} param0 参数
     * @returns 结果
     */
    async getContactCustomerServiceList({ token = getUserToken(), param }) {
        const response = await fetch(API_HOST + '/admin/server/data/getList', {
            method: 'POST',
            headers: { ...buildFetchUsetTokenHeaders(token), ...FetchJsonHeaders },
            body: JSON.stringify(param)
        })
        if (response.status === 204) {
            return true
        }

        return loginFailureHandle(await response.json())

    },

    /**
     * 保存联系客服
     * @param {*} param0 参数
     * @returns 结果
     */
    async saveContactCustomerServiceList({ token = getUserToken(), param }) {
        const response = await fetch(API_HOST + '/admin/server/data/addServerData', {
            method: 'POST',
            headers: { ...buildFetchUsetTokenHeaders(token), ...FetchJsonHeaders },
            body: JSON.stringify(param)
        })
        if (response.status === 204) {
            return true
        }

        return loginFailureHandle(await response.json())

    },
    /**
    * 删除联系客服
    * @param {*} param0 参数
    * @returns 结果
    */
    async delContactCustomerServiceList({ token = getUserToken(), serverId }) {
        const response = await fetch(API_HOST + `/admin/server/data/delById?serverId=${serverId}`, {
            method: 'POST',
            headers: { ...buildFetchUsetTokenHeaders(token), ...FetchJsonHeaders },
        })
        if (response.status === 204) {
            return true
        }

        return loginFailureHandle(await response.json())

    },

    /**
     * 排序联系客服
     * @param {*} param0 参数
     * @returns 结果
     */
    async sortContactCustomerServiceList({ token = getUserToken(), serverId, sort }) {
        const response = await fetch(API_HOST + `/admin/server/data/uptById?serverId=${serverId}&num=${sort}`, {
            method: 'POST',
            headers: { ...buildFetchUsetTokenHeaders(token), ...FetchJsonHeaders },
        })
        if (response.status === 204) {
            return true
        }

        return loginFailureHandle(await response.json())

    },


    /**
     * 用户问题反馈列表
     * @param {*} param0 参数
     * @returns 结果
     */
    async getFeedbackList({ token = getUserToken(), param }) {
        const response = await fetch(API_HOST + '/admin/user/feedback/getList', {
            method: 'POST',
            headers: { ...buildFetchUsetTokenHeaders(token), ...FetchJsonHeaders },
            body: JSON.stringify(param)
        })
        if (response.status === 204) {
            return true
        }

        return loginFailureHandle(await response.json())

    },

    /**
    * 用户问题反馈
    * @param {*} param0 参数
    * @returns 结果
    */
    async uptFeedback({ token = getUserToken(), feedbackId }) {
        const response = await fetch(API_HOST + `/admin/user/feedback/uptById?feedbackId=${feedbackId}`, {
            method: 'POST',
            headers: { ...buildFetchUsetTokenHeaders(token), ...FetchJsonHeaders },
        })
        if (response.status === 204) {
            return true
        }

        return loginFailureHandle(await response.json())
    },

    /**
    * 支获取付宝多商户配置
    * @param {*} param0 参数
    * @returns 结果
    */
    async getAlipayManyConfig({ token = getUserToken() }) {
        const response = await fetch(API_HOST + '/admin/config_many_alipay', {
            method: 'GET',
            headers: { ...buildFetchUsetTokenHeaders(token), ...FetchJsonHeaders },
        })
        if (response.status === 204) {
            return true
        }

        return loginFailureHandle(await response.json())
    },

    /**
    * 保存取付宝多商户配置
    * @param {*} param0 参数
    * @returns 结果
    */
    async saveAlipayManyConfig({ token = getUserToken(), param }) {
        const response = await fetch(API_HOST + '/admin/config_many_alipay', {
            method: 'POST',
            headers: { ...buildFetchUsetTokenHeaders(token), ...FetchJsonHeaders },
            body: JSON.stringify(param)
        })
        if (response.status !== 200) {
            throw await response.json()
        }

        return true
    },

    /**
    * 支获取付宝多商户配置
    * @param {*} param0 参数
    * @returns 结果
    */
    async getAlipayManyApps({ token = getUserToken() }) {
        const response = await fetch(API_HOST + '/admin/config_many_alipay/all/app', {
            method: 'GET',
            headers: { ...buildFetchUsetTokenHeaders(token), ...FetchJsonHeaders },
        })
        if (response.status === 204) {
            return true
        }

        return loginFailureHandle(await response.json())
    },
    /**
     * 代付
     * @param {*} param0 参数
     * @returns 结果
     */
    async ftPayment({ token = getUserToken(), orderId, remarks }) {
        const response = await fetch(API_HOST + `/admin/order/disbursements/payment/${orderId}?remark=${remarks}`, {
            method: 'POST',
            headers: { ...buildFetchUsetTokenHeaders(token), ...FetchJsonHeaders },
        })
        if (response.status === 204) {
            return true
        }

        return loginFailureHandle(await response.json())
    },
    /**
     * 代付(QL)
     * @param {*} param0 参数
     * @returns 结果
     */
    async qlPayment({ token = getUserToken(), orderId, remarks }) {
        const response = await fetch(API_HOST + `/admin/order/disbursements/qiling/payment/${orderId}?remark=${remarks}`, {
            method: 'POST',
            headers: { ...buildFetchUsetTokenHeaders(token), ...FetchJsonHeaders },
        })
        if (response.status === 204) {
            return true
        }

        return loginFailureHandle(await response.json())
    },

    /**
    * 代付(JST)
    * @param {*} param0 参数
    * @returns 结果
    */
    async jstPayment({ token = getUserToken(), orderId, remarks }) {
        const response = await fetch(API_HOST + `/admin/order/disbursements/jst/payment/${orderId}?remark=${remarks}`, {
            method: 'POST',
            headers: { ...buildFetchUsetTokenHeaders(token), ...FetchJsonHeaders },
        })
        if (response.status === 204) {
            return true
        }

        return loginFailureHandle(await response.json())
    },


    /**
     * 代付(ONE Pay)
     * @param {*} param0 参数
     * @returns 结果
     */
    async onePayment({ token = getUserToken(), orderId, remarks }) {
        const response = await fetch(API_HOST + `/admin/order/disbursements/one/payment/${orderId}?remark=${remarks}`, {
            method: 'POST',
            headers: { ...buildFetchUsetTokenHeaders(token), ...FetchJsonHeaders },
        })
        if (response.status === 204) {
            return true
        }

        return loginFailureHandle(await response.json())
    },

    /**
     * 代付(duola Pay)
     * @param {*} param0 参数
     * @returns 结果
     */
    async duolaPayment({ token = getUserToken(), orderId, remarks }) {
        const response = await fetch(API_HOST + `/admin/order/disbursements/duola/payment/${orderId}?remark=${remarks}`, {
            method: 'POST',
            headers: { ...buildFetchUsetTokenHeaders(token), ...FetchJsonHeaders },
        })
        if (response.status === 204) {
            return true
        }

        return loginFailureHandle(await response.json())
    },

    /**
     * 代付(duola Pay)
     * @param {*} param0 参数
     * @returns 结果
     */
    async aKingPayment({ token = getUserToken(), orderId, remarks }) {
        const response = await fetch(API_HOST + `/admin/order/disbursements/wangzhe/payment/${orderId}?remark=${remarks}`, {
            method: 'POST',
            headers: { ...buildFetchUsetTokenHeaders(token), ...FetchJsonHeaders },
        })
        if (response.status === 204) {
            return true
        }

        return loginFailureHandle(await response.json())
    },

    /**
     * 代付2
     * @param {*} param0 参数
     * @returns 结果
     */
    async hfbPayment({ token = getUserToken(), orderId, remarks }) {
        const response = await fetch(API_HOST + `/admin/order/huifubao/payment/${orderId}?remark=${remarks}`, {
            method: 'POST',
            headers: { ...buildFetchUsetTokenHeaders(token), ...FetchJsonHeaders },
        })
        if (response.status === 204) {
            return true
        }

        return loginFailureHandle(await response.json())
    },



    /**
    * 逾期还款订单
    * @param {*} param0 参数
    * @returns 结果
    */
    async overdueRepaymentOrder({ token = getUserToken(), data }) {
        const response = await fetch(API_HOST + '/admin/order/overdueRepaymentOrder', {
            method: 'POST',
            headers: { ...buildFetchUsetTokenHeaders(token), ...FetchJsonHeaders },
            body: JSON.stringify(data)
        })
        if (response.status !== 200) {
            throw await response.json()
        }
        return await response.json()
    },

    /**
     * 审核拒绝手机号导出
     * @param {*} param0 参数
     * @returns 结果
     */
    async getRefusePhoneExcel({ token = getUserToken(), isDownload }) {
        const response = await fetch(API_HOST + '/admin/order/getRefusePhoneExcel', {
            method: 'POST',
            headers: { ...buildFetchUsetTokenHeaders(token), ...FetchJsonHeaders },
            responseType: 'arraybuffer',
        })

        if (response.status === 204) {
            return true
        }

        const blob = await response.blob()

        if (isDownload) {
            blobDownloadFiles(blob, '审核拒绝手机号.xls')
        }

        return blob

    }

    ,

    /**
     * 今日已完成导出
     * @param {*} param0 参数
     * @returns 结果
     */
    async todayCompleteOrderListExcel({ token = getUserToken(), param, isDownload }) {
        const response = await fetch(API_HOST + '/admin/order/todayCompleteOrderListExcel', {
            method: 'POST',
            headers: { ...buildFetchUsetTokenHeaders(token), ...FetchJsonHeaders },
            responseType: 'arraybuffer',
            body: JSON.stringify(param)
        })

        if (response.status === 204) {
            return true
        }

        const blob = await response.blob()

        if (isDownload) {
            blobDownloadFiles(blob, '今日已完成.xls')
        }

        return blob

    }

    ,

    /**
     * 今日展期导出
     * @param {*} param0 参数
     * @returns 结果
     */
    async todayDeferExcel({ token = getUserToken(), param, isDownload }) {
        const response = await fetch(API_HOST + '/admin/order/todayDeferExcel', {
            method: 'POST',
            headers: { ...buildFetchUsetTokenHeaders(token), ...FetchJsonHeaders },
            responseType: 'arraybuffer',
            body: JSON.stringify(param)
        })

        if (response.status === 204) {
            return true
        }

        const blob = await response.blob()

        if (isDownload) {
            blobDownloadFiles(blob, '今日展期.xls')
        }

        return blob

    },
    /**
     * 会员列表导出
     * @param {*} param0 参数
     * @returns 结果
     */
    async exportUserList({ token = getUserToken(), param, isDownload }) {
        const response = await fetch(API_HOST + '/admin/user/getListExcel', {
            method: 'POST',
            headers: { ...buildFetchUsetTokenHeaders(token), ...FetchJsonHeaders },
            responseType: 'arraybuffer',
            body: JSON.stringify(param)
        })

        if (response.status === 204) {
            return true
        }

        const blob = await response.blob()

        if (isDownload) {
            blobDownloadFiles(blob, '会员列表.xls')
        }

        return blob

    },

    /**
     * 会员列表导出
     * @param {*} param0 参数
     * @returns 结果
     */
    async exportRiskBatchProcessing({ token = getUserToken(), param, isDownload }) {
        const response = await fetch(API_HOST + '/admin/batch/risk/getListExcel', {
            method: 'POST',
            headers: { ...buildFetchUsetTokenHeaders(token), ...FetchJsonHeaders },
            responseType: 'arraybuffer',
            body: JSON.stringify(param)
        })

        if (response.status === 204) {
            return true
        }

        const blob = await response.blob()

        if (isDownload) {
            blobDownloadFiles(blob, '会员风控批处理列表.xls')
        }

        return blob

    },

    /**
     * 逾期还款订单导出
     * @param {*} param0 参数
     * @returns 结果
     */
    async overdueRepaymentOrderExcel({ token = getUserToken(), param, isDownload }) {
        const response = await fetch(API_HOST + '/admin/order/overdueRepaymentOrderExcel', {
            method: 'POST',
            headers: { ...buildFetchUsetTokenHeaders(token), ...FetchJsonHeaders },
            responseType: 'arraybuffer',
            body: JSON.stringify(param)
        })

        if (response.status === 204) {
            return true
        }

        const blob = await response.blob()

        if (isDownload) {
            blobDownloadFiles(blob, '逾期还款订单列表.xls')
        }

        return blob

    },

    /**
     * 联系客服列表
     * @param {*} param0 参数
     * @returns 结果
     */
    async hfbRecharge({ token = getUserToken(), money }) {
        const response = await fetch(API_HOST + `/admin/order/huifubao/recharge?money=${money}`, {
            method: 'POST',
            headers: { ...buildFetchUsetTokenHeaders(token), ...FetchJsonHeaders },
        })
        if (response.status === 204) {
            return true
        }

        return loginFailureHandle(await response.json())

    },

    /**
     * 正常还款未借款导出
     * @param {*} param0 参数
     * @returns 结果
     */
    async getNormalRepaymentNotApplyListExcel({ token = getUserToken(), param, isDownload }) {
        const response = await fetch(API_HOST + '/admin/user/getNormalRepaymentNotApplyListExcel', {
            method: 'POST',
            headers: { ...buildFetchUsetTokenHeaders(token), ...FetchJsonHeaders },
            responseType: 'arraybuffer',
            body: JSON.stringify(param)
        })

        if (response.status === 204) {
            return true
        }

        const blob = await response.blob()

        if (isDownload) {
            blobDownloadFiles(blob, '正常还款未借款列表.xls')
        }

        return blob

    },





}