import { Form, DateRangePicker, Input, Select, Button, Table, Pagination, Tag } from 'element-react'
import React, { Component } from 'react'
import apiV2 from '../../api/api-v2'
import Paste from '../../components/paste'
import Money from '../../components/Money'
import { OrderType, OrderTypeListNoExtension as OrderTypeList, delayRepaymentUI, OrderBillMoneyType } from './types'
import moment from 'moment'
import { CommonTableFields } from './assembly/order'

import PropTypes from 'prop-types'
import OperatorInfo from '../member/operator_info'
import UserInfo from '../member/user_info'
import OrderInfo from './order_info'
import Condition from '../../components/Condition'
import { DeductionAmountTableColumn } from '../../utils/app'

/**
 * 订单
 */
export default class OrderList extends Component {

    static ListResultEmpty = { records: [], total: 0 }


    static propTypes = {
        queryParam: PropTypes.object,
        onRequestList: PropTypes.func,
        repaymentAmountField: PropTypes.string,
        deleteField: PropTypes.array
    }


    constructor(props) {
        super(props)

        const orderColumns = [
            DeductionAmountTableColumn,
            {
                label: '操作金额',
                prop: 'money',
                align: 'center',
                width: '120',
            },
            {
                label: '用户姓名',
                prop: 'userName',
                width: '100',
                align: 'center',
                render: ({ userName }) => <Paste content={userName} />,
                fixed: true
            },
            ...CommonTableFields,
            {
                label: '分期信息',
                prop: 'agingDetail',
                width: '100',
                align: 'center',
            },
            {
                label: '流水类型',
                prop: 'billTypeName',
                width: '100',
                align: 'center',
                render: ({ billType }) => {
                    const that = OrderBillMoneyType[billType] || { color: 'warning', name: '未知' }
                    return <Tag type={that.color}>{that.name}</Tag>
                }
            },
            {
                label: '支付方式',
                prop: 'payTypeName',
                align: 'center',
                width: '100',
            },
            {
                label: '三方支付流水号',
                prop: 'thirdNum',
                width: '160',
                align: 'center',
            },
            {
                label: '单号',
                prop: 'orderCode',
                width: '180',
                align: 'center',
                render: ({ orderCode }) => <Paste content={orderCode} />
            },
            {
                label: '渠道',
                prop: 'channel',
                width: '150',
                align: 'center',
                render: ({ channel }) => <Paste content={channel} />
            },

            {
                label: '借款额度',
                width: '120',
                align: 'center',
                prop: 'quota',
                render: ({ quota }) => <Money value={quota} />
            },
            {
                label: '申请借款周期',
                width: '140',
                align: 'center',
                prop: 'deadlineDay',
                render: ({ deadlineDay }) => <Money value={deadlineDay} />
            },
            {
                label: '借款次数',
                prop: 'num',
                align: 'center',
                width: '120',
            },

            {
                label: '手机号',
                prop: 'phone',
                align: 'center',
                width: '120',
            },

            {
                label: '服务费',
                prop: 'rateMoney',
                align: 'center',
                width: '120',
                render: ({ rateMoney }) => <Money value={rateMoney} />
            },
            {
                label: '逾期信息',
                prop: 'overdueAmount,overdueDay,overdueDerateAmount',
                align: 'center',
                width: '120',
                render: ({ overdueAmount, overdueDay, overdueDerateAmount }) =>
                    delayRepaymentUI('逾期', overdueAmount, overdueDay, overdueDerateAmount)
            },
            {
                label: '延期信息',
                prop: 'deferredAmount,deferredDay,derateAmount',
                align: 'center',
                width: '120',
                render: ({ deferredAmount, deferredDay }) =>
                    delayRepaymentUI('延期', deferredAmount, deferredDay)
            },

            {
                label: '订单类型',
                prop: 'type',
                width: '120',
                align: 'center',
                fixed: true,
                render: ({ type }) => {
                    const that = OrderType[type]
                    return <Tag type={that.theme}>{that.name}</Tag>
                }
            },
            {
                label: '是否新客',
                prop: 'num',
                width: '120',
                align: 'center',
                render: ({ num }) => {
                    return num > 0 ? (<i className="el-icon-close" />) : (<i color="red" className="el-icon-check" />)
                }

            },
            // {
            //     label: '雷达分',
            //     prop: 'kaiqiScore',
            //     width: '120',
            //     align: 'center',
            //     render: ({ kaiqiScore }) => <Money value={kaiqiScore} />
            // },
            // {
            //     label: '米融分',
            //     prop: 'zhimiScore',
            //     width: '120',
            //     align: 'center',
            //     render: ({ zhimiScore }) => <Money value={zhimiScore} />
            // },
            {
                label: '操作时间',
                prop: 'createTime',
                width: '140',
                align: 'center',

            },
            {
                label: '约定还款时间',
                prop: 'endTime',
                align: 'center',
                width: '150',
            },
            {
                label: '到账金额',
                prop: 'arrivalAmount',
                align: 'center',
                width: '120',
                render: ({ arrivalAmount }) => <Money value={arrivalAmount} />
            },
            {
                label: '应还金额',
                prop: 'shouldRepayAmount',
                align: 'center',
                width: '120',
                render: ({ shouldRepayAmount }) => <Money value={shouldRepayAmount} />
            },
            {
                label: '还款金额',
                prop: 'row',
                align: 'center',
                width: '120',
                render: (row) => <Money value={row[repaymentAmountField]} />
            },

            {
                label: '操作',
                prop: 'id',
                fixed: 'right',
                align: 'center',
                width: '150',
                render: ({ userId, orderId }) => {
                    return (
                        <>
                            <Button onClick={() => this.setState({ orderInfoId: orderId })} type="primary" size="mini">{'详情'}</Button>
                            <Button type="info" onClick={() => this.setState({ phoneInfoId: userId })} size="mini">{'手机详情'}</Button>
                            {/* <Button type="warning" onClick={() => this.setState({ userInfoId: userId })} size="mini">{'用户详情'}</Button> */}
                        </>)
                }

            },

        ]

        const deleteField = props.deleteField || []
        const repaymentAmountField = props.repaymentAmountField || 'actualRepaymentAmount'

        this.state = {
            orderColumns: orderColumns.filter(({ prop }) => !deleteField.includes(prop)),
            orderList: OrderList.ListResultEmpty,
            queryForm: {
                orderCode: '',
                size: 10,
                current: 1,
                type: '',
                channel: '',
                phone: '',
                beginTime: null,
                endTime: null,
            },
            userInfoId: null,
            phoneInfoId: null,
            orderInfoId: null
        }
    }


    componentDidMount() {
        this.loadRequest()
    }

    componentDidUpdate(preProps) {
        if (preProps.queryParam !== this.props.queryParam) {
            this.loadRequest()
        }
    }

    //组件销毁前调用，清除一些事件(比如定时事件)
    componentWillUnmount() {
        this.setState = () => {
            return
        }
    }

    loadRequest() {
        if (!this.props.queryParam) { this.getOrderList() } else {
            this.setState(Object.assign(this.state.queryForm, this.props.queryParam), this.getOrderList)
        }

    }

    async getOrderList() {
        const onRequestList = this.props.onRequestList
        let orderResult = null
        if (onRequestList) {
            orderResult = await onRequestList(this.state.queryForm)
        } else {
            orderResult = await apiV2.getCompletedUsersOrder({ data: this.state.queryForm })
        }

        const { body } = orderResult
        this.setState({ orderList: body || OrderList.ListResultEmpty })
    }

    changeQueryForm(name, value, formatFn, listRefresh) {
        if (formatFn) value = formatFn(value)

        const queryForm = Object.assign(this.state.queryForm, { [name]: value })
        this.setState({ queryForm }, () => {
            if (listRefresh) this.getOrderList()
        })
    }

    datesAdapterElUI(date1String, date2String) {
        if (!date1String || !date2String) return [null, null]
        return [new Date(date1String), new Date(date2String)]
    }

    changeQueryFormDate(value, nameStart, nameEnd) {
        const format = 'YYYY-MM-DD'
        let start = null, end = null
        if (value && value.length > 1) {
            start = moment(value[0]).format(format)
            end = moment(value[1]).format(format)
        }

        const queryForm = Object.assign(this.state.queryForm, { [nameStart]: start, [nameEnd]: end })
        this.setState({ queryForm })

    }

    exportOrderList() {
        apiV2.exportCompletedUsersOrder({ data: this.state.queryForm, isDownload: true })
    }

    render() {

        const { orderColumns, orderList, queryForm } = this.state
        const { queryParam } = this.props

        return (
            <>
                <Form inline>
                    <Form.Item label="订单号">
                        <Input value={queryForm.orderCode} onChange={(e) => this.changeQueryForm('orderCode', e)} />
                    </Form.Item>

                    <Form.Item label="用户姓名">
                        <Input value={queryForm.userName} onChange={(e) => this.changeQueryForm('userName', e)} />
                    </Form.Item>

                    <Form.Item label="手机号">
                        <Input value={queryForm.phone} onChange={(e) => this.changeQueryForm('phone', e)} />
                    </Form.Item>

                    <Form.Item label="渠道号">
                        <Input value={queryForm.channel} onChange={(e) => this.changeQueryForm('channel', e)} />
                    </Form.Item>

                    <Form.Item label="类型">
                        <Select value={queryForm.type} clearable onChange={(e) => this.changeQueryForm('type', e)}>
                            {OrderTypeList.map(({ key, name }) => <Select.Option key={key} label={name} value={key} />)}
                        </Select>
                    </Form.Item>


                    <Condition visible={queryParam && (!queryParam.beginTime || !queryParam.endTime)} tag="span">
                        <Form.Item label="下单时间">
                            <DateRangePicker value={this.datesAdapterElUI(queryForm.beginTime, queryForm.endTime)}
                                onChange={(e) => this.changeQueryFormDate(e, 'beginTime', 'endTime')} />
                        </Form.Item>
                    </Condition>

                    <Form.Item>
                        <Button onClick={() => this.getOrderList()} type="primary">{'搜索'}</Button>
                    </Form.Item>

                    <Form.Item>
                        <Button onClick={() => this.exportOrderList()} type="success">{'导出'}</Button>
                    </Form.Item>

                    <Table border columns={orderColumns} data={orderList.records} />

                    <Pagination total={orderList.total} current={queryForm.current} size={queryForm.size}
                        onCurrentChange={(e) => this.changeQueryForm('current', e, null, true)}
                        onSizeChange={(e) => this.changeQueryForm('size', e, null, true)}
                    />

                    <OrderInfo orderId={this.state.orderInfoId} onClose={() => this.setState({ orderInfoId: null })} />
                    <OperatorInfo userId={this.state.phoneInfoId} onClose={() => this.setState({ phoneInfoId: null })} />
                    <UserInfo userId={this.state.userInfoId} onClose={() => this.setState({ userInfoId: null })} />
                </Form>
            </>
        )
    }
}