import { Form, DateRangePicker, Input, Switch, Select, Button, Table, Pagination, Tag, DatePicker } from 'element-react'
import React, { Component } from 'react'
import apiV2 from '../../api/api-v2'
import Paste from '../../components/paste'
import Money from '../../components/Money'
import { OrderType, OrderTypeList, delayRepaymentUI, orderInfoButtonGroup } from '../loan/types'

import PropTypes from 'prop-types'

import OperatorInfo from '../member/operator_info'
import UserInfo from '../member/user_info'
import NewUserForm from '../loan/new_user_switch'

import OrderReduction from '../loan/order_reduction'
import OrderRepaymentFrom from '../loan/order_repayment_from'
import OrderInfo from '../loan/order_info'

import Condition from '../../components/Condition'
import OrderDelay from '../loan/order_delay'
import { CommonTableFields } from '../loan/assembly/order'
import { datesAdapterElUI, dateAdapterElUI, formatDatePeriod } from '../../utils/format'


/**
 * 订单-今日延期
 */
export default class OrderList extends Component {

    static ListResultEmpty = { records: [], total: 0 }

    static propTypes = {
        overdue: PropTypes.bool,
        fixedRightField: PropTypes.string,
        startTimeExpire: PropTypes.string,
        endTimeExpire: PropTypes.string,
        queryParam: PropTypes.object,
        readOnly: PropTypes.bool,
        exportNo: PropTypes.bool,
        showDatePick: PropTypes.bool,
    }


    constructor(props) {
        super(props)

        let shouldRepayAmountFixed = false
        let overdueAmountFixed = false

        const fixedRightField = props.fixedRightField || 'shouldRepayAmount'

        switch (fixedRightField) {
            case 'shouldRepayAmount': {
                shouldRepayAmountFixed = null
                break
            }
            case 'overdue': {
                overdueAmountFixed = 'right'
                break
            }
            default: {
                console.log(fixedRightField)
            }
        }

        // 延期默认值
        const delayTime = props.showDatePick ? null : new Date()

        this.state = {
            clickOrder: {},
            orderColumns: [
                {
                    label: '用户姓名',
                    prop: 'userName',
                    width: '100',
                    align: 'center',
                    render: ({ userName }) => <Paste content={userName} />,
                    fixed: true
                },
                {
                    label: '单号',
                    prop: 'orderCode',
                    width: '185',
                    align: 'center',
                    render: ({ orderCode }) => <Paste content={orderCode} />
                },
                {
                    label: '渠道',
                    prop: 'channel',
                    width: '150',
                    align: 'center',
                    render: ({ channel }) => <Paste content={channel} />
                },
                {
                    label: '借款额度',
                    width: '120',
                    align: 'center',
                    prop: 'quota',
                    render: ({ quota }) => <Money value={quota} />
                },
                {
                    label: '申请借款周期',
                    width: '140',
                    align: 'center',
                    prop: 'deadlineDay',
                    render: ({ deadlineDay }) => <Money value={deadlineDay} />
                },
                {
                    label: '借款次数',
                    prop: 'num',
                    align: 'center',
                    width: '120',
                },
                ...CommonTableFields,
                {
                    label: '约定还款时间',
                    prop: 'endTime',
                    align: 'center',
                    width: '150',
                },
                {
                    label: '手机号',
                    prop: 'phone',
                    align: 'center',
                    width: '120',
                    fixed: true
                },
                {
                    label: '服务费',
                    prop: 'rateMoney',
                    align: 'center',
                    width: '120',
                    render: ({ rateMoney }) => <Money value={rateMoney} />
                },
                {
                    label: '逾期信息',
                    prop: 'overdueAmount,overdueDay,overdueDerateAmount',
                    align: 'center',
                    width: '120',
                    fixed: overdueAmountFixed,
                    render: ({ overdueAmount, overdueDay }) =>
                        delayRepaymentUI('逾期', overdueAmount, overdueDay, 0)
                },
                {
                    label: '延期信息',
                    prop: 'deferredAmount,deferredDay,derateAmount',
                    align: 'center',
                    width: '120',
                    render: ({ deferredAmount, deferredDay, derateAmount }) =>
                        delayRepaymentUI('延期', deferredAmount, deferredDay, derateAmount)
                },

                {
                    label: '订单类型',
                    prop: 'type',
                    width: '120',
                    align: 'center',
                    render: ({ type }) => {
                        const that = OrderType[type]
                        return <Tag type={that.theme}>{that.name}</Tag>
                    }
                },
                {
                    label: '是否新客',
                    prop: 'num',
                    width: '120',
                    align: 'center',
                    render: ({ num }) => {
                        return num > 0 ? (<i className="el-icon-close" />) : (<i color="red" className="el-icon-check" />)
                    }

                },
                // {
                //     label: '雷达分',
                //     prop: 'kaiqiScore',
                //     width: '120',
                //     align: 'center',
                //     render: ({ kaiqiScore }) => <Money value={kaiqiScore} />
                // },
                // {
                //     label: '米融分',
                //     prop: 'zhimiScore',
                //     width: '120',
                //     align: 'center',
                //     render: ({ zhimiScore }) => <Money value={zhimiScore} />
                // },
                {
                    label: '下单时间',
                    prop: 'createTime',
                    width: '140',
                    align: 'center',

                },
                {
                    label: '到账金额',
                    prop: 'arrivalAmount',
                    align: 'center',
                    width: '120',
                    render: ({ arrivalAmount }) => <Money value={arrivalAmount} />
                },
                {
                    label: '应还金额',
                    prop: 'shouldRepayAmount',
                    align: 'center',
                    fixed: shouldRepayAmountFixed,
                    width: '120',
                    render: ({ shouldRepayAmount }) => <Money value={shouldRepayAmount} />
                },
                {
                    label: '放款时间',
                    prop: 'remitTime',
                    align: 'center',
                    width: '150',
                },
                {
                    label: '操作',
                    prop: 'id',
                    fixed: 'right',
                    align: 'center',
                    width: '150',
                    render: row => {
                        const { userId, orderId, type } = row
                        return (
                            <>
                                {this.props.readOnly ? orderInfoButtonGroup(this, orderId, userId) : (
                                    <>
                                        {orderInfoButtonGroup(this, orderId, userId)}
                                        <Button type="text" size="mini" onClick={() => this.setState({ reductionId: orderId })}>{'减免'}</Button>
                                        <Button type="text" size="mini" onClick={() => this.setState({ delayId: orderId })}>{'延期'}</Button>
                                        <Button type="text" size="mini" onClick={() => this.setState({ clickOrder: row, repaymentId: orderId, mpm: type === 3 })}>{'还款'}</Button>
                                    </>
                                )}

                            </>)
                    }
                },
                {
                    label: 'ID',
                    prop: 'orderId',
                    width: '60',
                    align: 'center',
                },
            ],
            orderList: OrderList.ListResultEmpty,
            queryForm: {
                delayTime,
                orderCode: '',
                size: 10,
                current: 1,
                type: '',
                isNewUser: null,
                beginAuditTime: null,
                endAuditTime: null,
                beginRemitTime: null,
                endRemitTime: null,
                beginTime: props.startTimeExpire,
                endTime: props.endTimeExpire,
                channel: '',
                phone: '',
                repaymentBeginTime: null,
                repaymentEndTime: null,
                overdueStatus: props.overdue || false
            },
            userInfoId: null,
            phoneInfoId: null,
            reductionId: null,
            delayId: null,
            repaymentId: null,
            orderInfoId: null,
            mpm: false
        }
    }

    componentDidMount() {
        this.loadRequest()
    }

    componentDidUpdate(preProps) {
        if (preProps.queryParam !== this.props.queryParam) {
            this.loadRequest()
        }
    }

    //组件销毁前调用，清除一些事件(比如定时事件)
    componentWillUnmount() {
        this.setState = () => {
            return
        }
    }

    loadRequest() {
        if (!this.props.queryParam) { this.getOrderList() } else {
            this.setState(Object.assign(this.state.queryForm, this.props.queryParam), this.getOrderList)
        }

    }


    async exportOrderList() {
        await apiV2.todayDeferExcel({ param: this.state.queryForm, isDownload: true })
    }

    async getOrderList() {
        const { body } = await apiV2.todayDeferOrderList({ data: this.state.queryForm })
        this.setState({ orderList: body || OrderList.ListResultEmpty })
    }

    changeQueryForm(name, value, formatFn, listRefresh) {
        if (formatFn) value = formatFn(value)

        const queryForm = Object.assign(this.state.queryForm, { [name]: value })
        this.setState({ queryForm }, () => {
            if (listRefresh) this.getOrderList()
        })
    }

    changeQueryFormDate(value, nameStart, nameEnd) {
        const [start, end] = formatDatePeriod(value)
        const queryForm = Object.assign(this.state.queryForm, { [nameStart]: start, [nameEnd]: end })
        this.setState({ queryForm })

    }

    changeQueryFormDate2(nameStart, value) {
        const [start] = formatDatePeriod([value, new Date()])
        this.changeQueryForm(nameStart, start)
    }

    onRepayment() {
        this.setState({ repaymentId: null, mpm: false })
        this.getOrderList()
    }

    get orderInstallment() {
        return this.state.clickOrder.type === 3
    }

    render() {

        const { orderColumns, orderList, queryForm } = this.state
        const { queryParam, exportNo, showDatePick } = this.props

        return (
            <>
                <Form inline>
                    <Form.Item label="订单号">
                        <Input value={queryForm.orderCode} onChange={(e) => this.changeQueryForm('orderCode', e)} />
                    </Form.Item>

                    <Form.Item label="手机号">
                        <Input value={queryForm.phone} onChange={(e) => this.changeQueryForm('phone', e)} />
                    </Form.Item>

                    <Form.Item label="用户姓名">
                        <Input value={queryForm.userName} onChange={(e) => this.changeQueryForm('userName', e)} />
                    </Form.Item>

                    <Form.Item label="渠道号">
                        <Input value={queryForm.channel} onChange={(e) => this.changeQueryForm('channel', e)} />
                    </Form.Item>

                    <Form.Item label="类型">
                        <Select value={queryForm.type} clearable onChange={(e) => this.changeQueryForm('type', e)}>
                            {OrderTypeList.map(({ key, name }) => <Select.Option key={key} label={name} value={key} />)}
                        </Select>
                    </Form.Item>

                    <Condition visible={queryParam && queryParam.isNewUser === null} tag="span">
                        <Form.Item label="查询新老客">
                            <NewUserForm value={queryForm.isNewUser} onChangeValue={(e) => this.changeQueryForm('isNewUser', e)} />
                        </Form.Item>
                    </Condition>

                    <Form.Item style={{ display: 'none' }}>
                        <Switch value={queryForm.overdueStatus} onChange={(e) => this.changeQueryForm('overdueStatus', e)} onText="逾期" offText="未逾 " />
                    </Form.Item>


                    <Form.Item label="审核时间">
                        <DateRangePicker value={datesAdapterElUI(queryForm.beginAuditTime, queryForm.endAuditTime)}
                            onChange={(e) => this.changeQueryFormDate(e, 'beginAuditTime', 'endAuditTime')} />
                    </Form.Item>

                    {showDatePick ?
                        <Form.Item label="延期时间">
                            <DatePicker value={dateAdapterElUI(queryForm.delayTime)} onChange={(e) => this.changeQueryFormDate2('delayTime', e)} />
                        </Form.Item> : null}

                    <Form.Item label="放款时间">
                        <DateRangePicker value={datesAdapterElUI(queryForm.beginRemitTime, queryForm.endRemitTime)}
                            onChange={(e) => this.changeQueryFormDate(e, 'beginRemitTime', 'endRemitTime')} />
                    </Form.Item>

                    <Condition visible={queryParam && (!queryParam.repaymentBeginTime || !queryParam.repaymentEndTime)} tag="span">
                        <Form.Item label="还款时间">
                            <DateRangePicker value={datesAdapterElUI(queryForm.repaymentBeginTime, queryForm.repaymentEndTime)}
                                onChange={(e) => this.changeQueryFormDate(e, 'repaymentBeginTime', 'repaymentEndTime')} />
                        </Form.Item>
                    </Condition>

                    <Condition visible={!this.props.startTimeExpire && !this.props.endTimeExpire} tag="span">
                        <Form.Item label="下单时间">
                            <DateRangePicker value={datesAdapterElUI(queryForm.beginTime, queryForm.endTime)}
                                onChange={(e) => this.changeQueryFormDate(e, 'beginTime', 'endTime')} />
                        </Form.Item>
                    </Condition>


                    <Form.Item>
                        <Button onClick={() => this.getOrderList()} type="primary">{'搜索'}</Button>
                    </Form.Item>

                    {!exportNo ?
                        <Form.Item>
                            <Button onClick={() => this.exportOrderList()} type="success">{'导出'}</Button>
                        </Form.Item> : null}

                    <Table border columns={orderColumns} data={orderList.records} />


                    <Pagination total={orderList.total} current={queryForm.current} size={queryForm.size}
                        onCurrentChange={(e) => this.changeQueryForm('current', e, null, true)}
                        onSizeChange={(e) => this.changeQueryForm('size', e, null, true)}
                    />

                    <OrderInfo orderId={this.state.orderInfoId} onClose={() => this.setState({ orderInfoId: null })} />
                    <OrderRepaymentFrom showRepaymentMethod={this.state.mpm} orderId={this.state.repaymentId} onClose={() => this.onRepayment()} />
                    <OrderDelay orderId={this.state.delayId} onClose={() => this.setState({ delayId: null })} />
                    <OrderReduction orderId={this.state.reductionId} onClose={() => this.setState({ reductionId: null })} />
                    <OperatorInfo userId={this.state.phoneInfoId} onClose={() => this.setState({ phoneInfoId: null })} />
                    <UserInfo userId={this.state.userInfoId} onClose={() => this.setState({ userInfoId: null })} />
                </Form>
            </>
        )
    }
}
